
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.FrontRoyalStore = window.Smartly.locales.modules.en.back_royal.FrontRoyalStore || {};
window.Smartly.locales.modules.en.back_royal.FrontRoyalStore.showStorageQuotaExceededModal = window.Smartly.locales.modules.en.back_royal.FrontRoyalStore.showStorageQuotaExceededModal || {};
window.Smartly.locales.modules.en.back_royal.FrontRoyalStore.showStorageQuotaExceededModal = {...window.Smartly.locales.modules.en.back_royal.FrontRoyalStore.showStorageQuotaExceededModal, ...{
    "title": "Storage Limit Exceeded",
    "continue": "Continue",
    "in_offline_mode_message": "You've exceeded the storage capacity allowed for the {{brandName}} app on your device. In order to continue, you must free up more space on your device or go back online.",
    "not_in_offline_mode_message": "You've exceeded the storage capacity allowed for the {{brandName}} app on your device. In order to continue, you must free up more space on your device."
}
}
    