import { type Permutation } from '@Types';
import { type ReactElement } from 'react';
import clsx from 'clsx';

export const FRONT_ROYAL_SPINNER_ARIA_LABEL = 'Loading...';

interface Props {
    className?: Permutation<'fixed' | 'centered' | 'no-delay' | 'delay-2500' | 'no-top-margin' | string>;
    color?: 'blue' | 'white' | 'force-coral' | 'force-white' | 'force-brand';
}

export default function FrontRoyalSpinner(props: Props): ReactElement {
    // FIXME: The color is managed by the .front-royal-spinner classname and the associated front_royal_spinner.scss
    // instead of using Tailwind classes. This is because we have both a React spinner and Angular spinner,
    // and this keeps the coloring rules DRY. Once we have fully converted to React, we can consider
    // using Tailwind classes for the color here based on the useTheme() hook and its bgColor property.
    const { className = '', color = '' } = props;
    return (
        <div
            className={clsx('front-royal-spinner', className, color)}
            data-testid="front-royal-spinner"
            aria-label={FRONT_ROYAL_SPINNER_ARIA_LABEL}
        >
            <div className="wrapper">
                <div className="bounce1" />
                <div className="bounce2" />
                <div className="bounce3" />
            </div>
        </div>
    );
}
