import angularModule from 'FrontRoyalLinkedIn/angularModule/scripts/linkedin_module';

angularModule.factory('LinkedinOauthService', [
    '$injector',
    $injector => {
        const $window = $injector.get('$window');
        const $q = $injector.get('$q');
        const $rootScope = $injector.get('$rootScope');
        const ErrorLogService = $injector.get('ErrorLogService');

        const callback = callbackData => {
            try {
                callbackData.profile = JSON.parse(callbackData.profileJsonString);
            } catch (e) {
                callbackData.profile = {};
            }
            $rootScope.$emit('LinkedinOauthService.linkedInProfileResponse', callbackData);
        };

        // Global function that is called by our backend linkedin/popup_callback.
        // FIXME: consider using postMessage
        $window.onLinkedInProfileFetched = $window.onLinkedInProfileFetched || callback;

        class LinkedInProfile {
            constructor(profileData) {
                this.profileData = profileData;

                // See https://docs.microsoft.com/en-us/linkedin/shared/references/v2/profile/profile-picture
                // See https://github.com/decioferreira/omniauth-linkedin-oauth2/blob/master/lib/omniauth/strategies/linkedin.rb#L109
                if (this.profileData?.profilePicture?.['displayImage~']?.elements?.length) {
                    // we've seen some instances where displayImage is populated, but without any elements
                    const element = this.profileData.profilePicture['displayImage~'].elements.pop();
                    if (element?.identifiers?.length) {
                        this.pictureUrl = element.identifiers.shift().identifier;
                    }
                }
            }
        }

        return {
            getProfile() {
                let cancelRootScopeListener;

                // eslint-disable-next-line consistent-return
                return $q((resolve, reject) => {
                    // Launch OAuth popup that navigates to an endpoint on our server that then
                    // sends the user to LinkedIn. Listen for a message from the popup to receive
                    // either the profile data or the error that prevented it from being retrieved.
                    //
                    // Note: We wanted to listen to the 'beforeunload' event to detect if the popup is closed
                    // so that we could reject the promise to let a directive do something like stop a spinner.
                    // We ran into a lot of issues with this. First, when we were opening the window to
                    // '/linkedin/auth', which redirects to LinkedIn, trying to attach an event listener
                    // threw a security issues due to CORS. Next we tried opening to a blank page, attaching the
                    // listener, then setting the location.href to '/linkedin/auth', but actually the 'beforeunload'
                    // event is called when leaving your domain, and we also realized that we'd probably hit the
                    // security issue still if you closed the window (fired the listener code) while at LinkedIn. We found
                    // a hacky polling solution to detect if the popup is closed, but that complexity didn't seem
                    // worth it.
                    const popupWindow = $window.open('/linkedin/auth', 'linkedin-auth-popup', 'height=600,width=800');
                    if (!popupWindow) {
                        return $q.reject({
                            errorDescription: 'Error loading popup window',
                        });
                    }

                    popupWindow.focus?.();

                    // If the user completes authorization, we will resolve or reject as needed
                    cancelRootScopeListener = $rootScope.$on(
                        'LinkedinOauthService.linkedInProfileResponse',
                        (_event, callbackData) => {
                            // See https://docs.microsoft.com/en-us/linkedin/shared/authentication/authorization-code-flow?context=linkedin/consumer/context#application-is-rejected
                            const realError = !['user_cancelled_login', 'user_cancelled_authorize'].includes(
                                callbackData.error,
                            );

                            if (realError && callbackData.errorDescription) {
                                reject({
                                    error: callbackData.error,
                                    errorDescription: callbackData.errorDescription,
                                });

                                // Note: We log possible_csrf_attack on the server in linkedin_controller
                                if (callbackData.error !== 'possible_csrf_attack') {
                                    ErrorLogService.notify(callbackData.errorDescription, undefined, callbackData);
                                }
                            } else {
                                resolve(new LinkedInProfile(callbackData.profile));
                            }
                        },
                    );
                }).finally(() => {
                    cancelRootScopeListener();
                });
            },
        };
    },
]);
