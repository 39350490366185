import FrontRoyalStore from './FrontRoyalStore';
import { requestInterceptor, responseErrorInterceptor, INTERCEPTOR_PRIORITY } from './interceptors';
import 'PrioritizedInterceptors/angularModule';
import frontRoyalStoreProvider from './frontRoyalStoreProvider';

const FrontRoyalStoreModule = angular.module('FrontRoyalStore', ['prioritizedInterceptors']);

FrontRoyalStoreModule.factory('frontRoyalStore', [
    '$injector',
    $injector => {
        const frontRoyalStore = new FrontRoyalStore($injector);
        const $rootScope = $injector.get('$rootScope');
        frontRoyalStoreProvider.set(frontRoyalStore);

        // See watchEnableFrontRoyalStoreOnCurrentUser, which is called in FrontRoyal/angularModuleAngular/index.js,
        // to see how we enable/disable the store based on the flag on the user.  It
        // would kind of make sense to call that here, but it makes it simpler in
        // the OfflineMode specs if that is not getting set up here automatially.

        $rootScope.$on('$destroy', () => {
            frontRoyalStoreProvider.set(null);
            frontRoyalStore.destroy();
        });

        return frontRoyalStore;
    },
]);

FrontRoyalStoreModule.factory('FrontRoyalStoreInterceptor', [
    '$injector',
    $injector => {
        const $q = $injector.get('$q');
        return {
            request: config => $q.when(requestInterceptor(config, $injector)),
            responseError: response => $q.when(responseErrorInterceptor(response)),
        };
    },
]);

// See comment near the call tp setupFrontRoyalStoreInterceptors in FrontRoyal/angularModuleAngular/index.js
// in order to understand why this is setup this way.
FrontRoyalStoreModule.constant('setupFrontRoyalStoreInterceptors', $injector => {
    const PrioritizedInterceptorsProvider = $injector.get('PrioritizedInterceptorsProvider');
    PrioritizedInterceptorsProvider.addInterceptor(INTERCEPTOR_PRIORITY, 'FrontRoyalStoreInterceptor');
});

export default FrontRoyalStoreModule;
