// taken from http://tobiasahlin.com/spinkit/

import cacheAngularTemplate from 'cacheAngularTemplate';
import template from 'FrontRoyalSpinner/angularModule/views/front_royal_spinner.html';

const frontRoyalSpinnerModule = angular.module('FrontRoyalSpinner', []);

const templateUrl = cacheAngularTemplate(frontRoyalSpinnerModule, template);
frontRoyalSpinnerModule.directive('frontRoyalSpinner', [
    '$injector',
    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            link(scope, elem) {
                elem.addClass('front-royal-spinner');
            },
        };
    },
]);

export default frontRoyalSpinnerModule;
