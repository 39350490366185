const frontRoyalHref = (): string => {
    let href: string;
    try {
        href = window.location.href;
    } catch (err) {
        // We've seen errors on ci where window.document is undefined
        href = '';
    }
    if (window.CORDOVA) {
        return `${window.CORDOVA.appIdentifier}${href.split(/capacitor:\/\/localhost#/)[1]}`;
    }
    return href;
};

export { frontRoyalHref };
