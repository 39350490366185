import angularModule from 'HelpScoutBeacon/angularModule/scripts/help_scout_beacon_module';

angularModule.factory('helpScoutBeacon', [
    '$injector',
    $injector => {
        const $window = $injector.get('$window');
        const $document = $injector.get('$document');
        const $location = $injector.get('$location');
        const $rootScope = $injector.get('$rootScope');
        const $interval = $injector.get('$interval');
        const $q = $injector.get('$q');

        let loadPromise;
        let currentBeaconId = null;

        // hack around eslint complaining when we call a capitalized function
        function beacon() {
            return $window.Beacon;
        }

        function callHelpscoutLoaderCode() {
            const window = $window;
            const document = $document[0];

            // This is the code that helpscout provides. They want you to put it in the head
            // of you html, but we want to lazy load it. onLazyLoad does not work because that
            // javascript does not put Beacon onto the window.  This code below is what hoists
            // it onto the window.

            /* eslint-disable */
            !(function (e, t, n) {
                function a() {
                    var e = t.getElementsByTagName('script')[0],
                        n = t.createElement('script');
                    (n.type = 'text/javascript'),
                        (n.async = !0),
                        (n.src = 'https://beacon-v2.helpscout.net'),
                        e.parentNode.insertBefore(n, e);
                }
                if (
                    ((e.Beacon = n =
                        function (t, n, a) {
                            e.Beacon.readyQueue.push({
                                method: t,
                                options: n,
                                data: a,
                            });
                        }),
                    (n.readyQueue = []),
                    'complete' === t.readyState)
                )
                    return a();
                e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1);
            })(window, document, window.Beacon || function () {});
            /* eslint-enable */
        }

        let watchDisablers = [];

        const helpScoutBeacon = {
            startWatching() {
                addMobileKeyboardListener();

                watchDisablers.push(
                    $rootScope.$watch(
                        () => {
                            if (!$rootScope.currentUser || !$location.path()) {
                                return null;
                            }
                            return `${$rootScope.currentUser.programType}:${$location.path()}`;
                        },
                        () => {
                            if ($location.path() !== '/settings/application') {
                                disable();
                                return;
                            }

                            const helpScoutBeaconID = $rootScope.currentUser?.helpScoutBeaconID;
                            if (helpScoutBeaconID) {
                                enable(helpScoutBeaconID);
                            } else {
                                disable();
                            }
                        },
                    ),
                );

                watchDisablers.push(
                    $rootScope.$watch(
                        () => $location.url(),
                        () => {
                            // When the url changes and we already have a beacon visible, we need
                            // to call suggest
                            if (currentBeaconId) {
                                beacon()('suggest');
                            }
                        },
                    ),
                );
            },

            stopWatching() {
                disable();
                _.forEach(watchDisablers, fn => {
                    fn();
                });
                watchDisablers = [];
            },

            openAskScreen() {
                const helpScoutBeaconID = $rootScope.currentUser?.helpScoutBeaconID;
                if (helpScoutBeaconID) {
                    enable(helpScoutBeaconID);

                    // This'll expand the Helpscout Beacon and automatically navigate the user to the "Ask" page.
                    beacon()('open');
                    beacon()('navigate', '/ask');

                    // Normally, clicking the "close" button simply closes the expanded window and leaves the little
                    // HelpScout beacon in the bottom right of the user's screen. In this particular use case,
                    // we actually want it to be completely removed from the screen instead, so we call `disable()`.
                    beacon()('on', 'close', () => disable());
                }
            },

            // Here rather than an anonymous function so it can be mocked out in specs
            _loadScript() {
                if (loadPromise) {
                    return loadPromise;
                }

                // Run some code that will start trying to load the script
                // and then poll for it to show up on the window.  See comment
                // inside of callHelpscoutLoaderCode for why we can't use ocLazyLoad here
                callHelpscoutLoaderCode();

                loadPromise = $q(resolve => {
                    const delay = 200;
                    const intervalPromise = $interval(
                        () => {
                            if (beacon()) {
                                // make it lower-case so it matches eslint requirements
                                $interval.cancel(intervalPromise);
                                resolve();
                            }
                        },
                        delay,
                        // give up after 30 seconds
                        30000 / delay,
                        // do not invoke $apply
                        false,
                    );
                });

                return loadPromise;
            },
        };

        function addMobileKeyboardListener() {
            $window.addEventListener('keyboardDidHide', () => {
                const helpScoutBeaconID = $rootScope.currentUser?.helpScoutBeaconID;
                if (helpScoutBeaconID && $location.path() === '/settings/application') {
                    enable(helpScoutBeaconID);
                }
            });
            $window.addEventListener('keyboardDidShow', () => {
                disable();
            });
        }

        function disable() {
            if (beacon() && currentBeaconId) {
                beacon()('destroy', currentBeaconId);
                $('body').removeClass('showing-helpscout-beacon');
            }
            currentBeaconId = null;
        }

        function enable(id) {
            if (currentBeaconId !== id) {
                disable();
            }
            if (currentBeaconId === id) {
                return;
            }
            currentBeaconId = id;
            helpScoutBeacon._loadScript().then(() => {
                // If we failed to load the script, abort
                if (!beacon()) {
                    return;
                }

                // If the id has changed while we were loading, abort
                if (currentBeaconId !== id) {
                    return;
                }

                beacon()('init', id);
                $('body').addClass('showing-helpscout-beacon');
            });
        }

        return helpScoutBeacon;
    },
]);
