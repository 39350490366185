import transformKeyCase from 'Utils/transformKeyCase';
import { type CamelCasedPublishedLessonContent, type PublishedLessonContent } from './FrontRoyalStore.types';

export function snakeCasePublishedLessonContent(
    lessonContent: CamelCasedPublishedLessonContent,
): PublishedLessonContent {
    return transformKeyCase(lessonContent, { to: 'snakeCase', exclude: ['devicePixelRatio', 'isMobile'] });
}

export function camelizePublishedLessonContent(
    lessonContent: PublishedLessonContent,
): CamelCasedPublishedLessonContent {
    return transformKeyCase(lessonContent, { to: 'camelCase' });
}
