import clsx from 'clsx';
import { type SvgProps } from 'FrontRoyalIcon';

export function SvgSearch24(props: SvgProps) {
    const { className, ...rest } = props;
    const classes = clsx('svg-search-24', className);

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" className={classes} {...rest}>
            <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
                <circle cx="10" cy="10" r="7" />
                <path strokeLinecap="round" strokeLinejoin="round" d="m15.5 14.5 6 6" />
            </g>
        </svg>
    );
}
