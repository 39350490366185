import { flushStoredCurrentUser } from 'StoredCurrentUser';
import { flushStoredLessonProgress } from 'StoredProgress';
import { flushStoredEvents } from 'StoredEvent';
import { logTinyEvent } from 'TinyEventLogger';
import { startSentryTransaction, FLUSH_STORE_TRACING_NAME } from 'ErrorLogging';
import NetworkConnection from 'NetworkConnection';
import { DisconnectedError } from 'DisconnectedError';

export async function hasUnflushedData(injector) {
    const frontRoyalStore = injector.get('frontRoyalStore');
    return frontRoyalStore.retryOnHandledError(
        async db => {
            if (await db.currentUsers.where('synced_to_server').equals(0).count()) {
                return true;
            }
            // Lesson progress isn't removed from the store when it gets flushed;
            // we just set `synced_to_server = 1` when it gets flushed, so unflushed
            // records will have `synced_to_server = 0`.
            if (await db.lessonProgress.where('synced_to_server').equals(0).count()) {
                return true;
            }
            if (await db.events.count()) {
                return true;
            }
            return false;
        },
        {
            name: 'hasUnflushedData',

            // tbh, I'm not sure why we need this here, but https://trello.com/c/PyC7XwrA/
            allowDisabledFrontRoyalStore: true,
        },
    );
}

async function flushItemsFromStore($injector, flushCallbacks) {
    // Regardless of whether or not we have actually entered offline mode,
    // we do not need to send these requests if we are offline.  We can just
    // return false.
    if (NetworkConnection.offline) {
        return false;
    }

    // These will reject with DisconnectedError if we lose the connection
    // while they are in flight (See handling of requests with
    // flushing_front_royal_store in front_rotal_api_error_handler)
    const transaction = startSentryTransaction($injector, FLUSH_STORE_TRACING_NAME);
    try {
        await Promise.all(flushCallbacks.map(cb => cb($injector)));
    } catch (err) {
        // If we could not flush to the server, return false.  We don't treat this as an
        // error, but whoever called flushStore might want to know that we
        // didn't actually flush
        if (err.constructor === DisconnectedError) {
            return false;
        }

        throw err;
    }
    transaction.finish();

    return true;
}

/*
    This method is very similar to `flushStore`, but it only flushes items that get
    removed from the store after being flushed. This is particularly handy when you're
    trying to free up storage space while avoiding QuotaExceededErrors. Note that since
    lesson progress and the currentUser don't get removed from the store upon being flushed
    (we sync them from the store to the server and back) we're not flushing them as part
    of this method because syncing it back to the store from the server can result in
    a QuotaExceededError.
*/
export async function flushRemovableItemsFromStore($injector) {
    return flushItemsFromStore($injector, [flushStoredEvents]);
}

export default async function flushStore($injector) {
    try {
        const returnValue = await flushItemsFromStore($injector, [
            flushStoredCurrentUser,
            flushStoredLessonProgress,
            flushStoredEvents,
        ]);
        return returnValue;
    } catch (err) {
        const message = err?.message || 'An unknown error occurred';
        logTinyEvent(
            'front_royal_store:error_in_flush',
            {
                label: message,
                message,
                error: err?.name,
            },
            { serverTimeOrConfigFactory: $injector.get('ConfigFactory') },
        );
        throw err;
    }
}
