
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.linkedin = window.Smartly.locales.modules.en.back_royal.linkedin || {};
window.Smartly.locales.modules.en.back_royal.linkedin.linkedin_profile_button = window.Smartly.locales.modules.en.back_royal.linkedin.linkedin_profile_button || {};
window.Smartly.locales.modules.en.back_royal.linkedin.linkedin_profile_button = {...window.Smartly.locales.modules.en.back_royal.linkedin.linkedin_profile_button, ...{
    "add_to_profile_img_src": "https://download.linkedin.com/desktop/add2profile/buttons/en_US.png",
    "add_to_profile_alt": "Add to LinkedIn Profile"
}
}
    