// iguana service wrapper class
// currently only opening up /api/users.json which returns info about the current user
export default angular.module('FrontRoyal.Upload', []).factory('frontRoyalUpload', [
    '$injector',
    $injector => {
        const Upload = $injector.get('Upload');
        const HttpQueue = $injector.get('HttpQueue');
        const TranslationHelper = $injector.get('TranslationHelper');
        const ApiErrorHandler = $injector.get('ApiErrorHandler');
        const ErrorLogService = $injector.get('ErrorLogService');
        const $q = $injector.get('$q');
        const translationHelper = new TranslationHelper('front_royal_upload.front_royal_upload');

        /*
            This function takes the file and errFiles arguments that come from ngf-select.
            The third argument is a function that takes a valid file and returns options
            that will be passed along to the `upload()` method.

            We use a function as the third argument, rather than just taking in the options,
            because the handler for ngf-select might be called with file=null, or with an invalid
            file.  By doing this as a function that is passed in, developers do not have to worry about
            null checks and things, since the function is only called once we know we have a valid file.

            Example usage:

            scope.onFileSelect = (file, errFiles) => {

                scope.errMessage = null;
                scope.uploading = true;

                frontRoyalUpload.handleNgfSelect(file, errFiles, file => ({
                    url: `${$window.ENDPOINT_ROOT}/api/upload_path.json`,
                    data: {
                        file
                    }
                })).then(response => {
                    scope.doSomethingResponse(response.data.contents.things[0]);
                }).catch(err => {
                    scope.errMessage = err && err.message;
                }).finally(() => {
                    scope.uploading = false;
                });
            };
        */
        function handleNgfSelect(fileOrfiles, errFiles, fn) {
            // After an error, this gets called again when the file upload is clicked for some reason,
            // before a file has been selected.  That's just confusing.  To see the issue, try to upload a file
            // that is too big on the admin_cohort_enrollment_status UI.  When you try again after seeing the error,
            // you will see a generic error modal popup as the file selector is showing up.
            const hasFile = (Array.isArray(fileOrfiles) && fileOrfiles[0]) || fileOrfiles;
            if (!hasFile && !errFiles[0]) {
                return $q.reject({});
            }

            return $q((resolve, reject) => {
                const errFile = errFiles && errFiles[0];

                if (errFile) {
                    let message;
                    if (errFile.$error === 'maxSize') {
                        message = translationHelper.get('max_size_exceeded', { size: errFile.$errorParam });
                    } else {
                        message = translationHelper.get('unknown_file_issue');
                    }
                    reject({ message });
                    return;
                }

                if (!hasFile) {
                    // The author of ng-file-upload confirmed that if you clear out the value and
                    // re-upload then the ngf-select function will be called with an empty value. It would
                    // be nice to utilize this DRY code to handle this so we don't have to put checks at every
                    // usage of this function, so I think we can just return an empty error object since we
                    // only show err.message in the UI. Then the promise will finish and the ngf-select function
                    // will be called again with the file.
                    // See https://github.com/danialfarid/ng-file-upload/issues/992#issuecomment-139058375
                    reject({});
                    return;
                }

                const opts = fn(fileOrfiles);
                upload(opts)
                    .then(response => {
                        if (response.status === 200) {
                            resolve(response);
                        } else {
                            ErrorLogService.notify('Unexpected upload response', null, response);
                            reject();
                        }
                    })
                    .catch(response => reject(response));
            });
        }

        /*
            In most cases, you will want to use handleNgfSelect, which wraps `upload`.  For an example
            of where we want to use `upload()` directly, see upload-avatar-dir.

            example opts:
            {
                url: S3TranscriptAsset.UPLOAD_URL,
                data: {
                    record: {
                        file: file
                    }
                },
                supportedFormatsForErrorMessage: '.png, .jpg, .pdf' // (do not use English in here)
            }
        */
        function upload(opts) {
            return Upload.upload({ 'FrontRoyal.ApiErrorHandler': { skip: true }, ...opts }).then(
                response => response,
                response => {
                    if (response.status === 406) {
                        let message;
                        const fileSizeMatch = response.data.message.match(/must be less than ([^,]+)/);

                        if (fileSizeMatch) {
                            message = translationHelper.get('max_size_exceeded', {
                                size: fileSizeMatch[1],
                            });
                        } else if (opts.supportedFormatsForErrorMessage) {
                            message = translationHelper.get('that_file_is_not_valid_allowed_types_are', {
                                allowed_types: opts.supportedFormatsForErrorMessage,
                            });
                        } else {
                            message = translationHelper.get('that_file_is_not_valid');
                        }

                        throw new Error(message);
                    }

                    // 409 indicates a conflict.  In this case, we unfreeze the queue and then reject the promise.
                    // In cases where this error code can occur, the rejection should be handled at the place where `upload` is called.
                    else if (response.status === 409) {
                        HttpQueue.unfreezeAfterError(response.config);
                        throw response;
                    } else if (response.config) {
                        response.config['FrontRoyal.ApiErrorHandler'].skip = false;
                    }
                    return ApiErrorHandler.onResponseError(response);
                },
            );
        }

        return { upload, handleNgfSelect };
    },
]);
