import clsx from 'clsx';
import { type SvgProps } from 'FrontRoyalIcon';

export function SvgPlay36(props: SvgProps) {
    const { className, ...rest } = props;
    const classes = clsx('svg-play-36', className);

    return (
        <svg width={36} height={36} viewBox="0 0 36 36" className={classes} {...rest}>
            <g fill="none" fillRule="evenodd">
                <rect width="36" height="36" fill="currentColor" rx="18" />
                <path
                    fill="#FFF"
                    d="m15.772 11.9 8.336 5.821a1 1 0 0 1 0 1.64l-8.336 5.822a1 1 0 0 1-1.573-.82V12.72a1 1 0 0 1 1.573-.82z"
                />
            </g>
        </svg>
    );
}
