import angularModule from 'HttpWrappers/angularModule/scripts/http_wrappers_module';

angularModule.factory('ngHttpWrapper', [
    '$injector',
    $injector => {
        const $http = $injector.get('$http');

        // Mimics fetch signature and return value (a native promise), see https://github.com/microsoft/TypeScript/blob/v4.6.3/lib/lib.dom.d.ts#L16450
        /**
         * @param {RequestInfo} input
         * @param {RequestInit} [init]
         * @returns {Promise<Response>}
         */
        return (input, init = {}) => {
            if (typeof input !== 'string') {
                throw new Error('Must pass RequestInfo as a string');
            }

            const method = init.method ? init.method.toUpperCase() : 'GET';
            const body = init.body && JSON.parse(init.body);

            let qPromise;

            switch (method) {
                case 'GET':
                    qPromise = $http.get(input);
                    break;
                case 'POST':
                    qPromise = $http.post(input, body);
                    break;
                case 'PUT':
                    qPromise = $http.put(input, body);
                    break;
                case 'DELETE':
                    qPromise = $http.delete(input);
                    break;
                case 'PATCH':
                    throw new Error('Not implemented');
                default:
                    throw new Error(`Request method "${method}" is not supported`);
            }

            return new Promise((resolve, reject) => {
                qPromise
                    .then(response => {
                        resolve(
                            new Response(JSON.stringify(response.data), {
                                status: response.status,
                                statusText: response.statusText,
                            }),
                        );
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        };
    },
]);
