import clsx from 'clsx';
import { type SvgProps } from 'FrontRoyalIcon';

export function SvgClose24(props: SvgProps) {
    const { className, ...rest } = props;
    const classes = clsx('svg-close-24', className);

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" className={classes} {...rest}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M19.92 2 12 9.918 4.083 2 2 4.079l7.92 7.92L2 19.919 4.082 22 12 14.08 19.92 22 22 19.918 14.081 12 22 4.079z"
                    fill="currentColor"
                />
                <path d="M0 0h24v24H0z" />
            </g>
        </svg>
    );
}
