import * as userAgentHelper from 'userAgentHelper';
import automationMode from 'automationMode';

export const INDEXED_DB = 'indexedDB';
export const FAKE_INDEXED_DB = 'fake-indexeddb';
export const SQLITE_PLUGIN = 'sqlitePlugin';

export default function supportedDbTechnology() {
    // When running performance tests, we don't want to use FAKE_INDEXED_DB, since
    // that's not generally what people will be using in the wild. We have to force
    // that because the Chromium browser apparently has `navigator.storage?.persist = false`
    if (automationMode().performanceTest) {
        return INDEXED_DB;
    }

    // See the section in front-royal-store.md labeled `Db Technologies` for details on the logic here.
    const isIosApp = window.clientIdentifier === 'ios';
    const browserReliablySupportsIndexedDBAndPersist = window.CORDOVA && !isIosApp;

    window.userAgentHelper = userAgentHelper;
    if (browserReliablySupportsIndexedDBAndPersist && window.indexedDB) {
        return INDEXED_DB;
    }
    if (window.sqlitePlugin) {
        return SQLITE_PLUGIN;
    }

    return FAKE_INDEXED_DB;
}
