import { memo } from 'react';
import { ModalBackdrop } from './ModalBackdrop';

function FrontRoyalModalComponent({ title, children }: { title?: string; children: React.ReactNode }) {
    return (
        <ModalBackdrop>
            {/* Modal Box */}
            <div className="tw-mx-auto tw-flex tw-w-full tw-max-w-sm tw-flex-col tw-items-center tw-justify-center tw-rounded-lg tw-bg-white tw-p-6 tw-shadow-[0_5px_15px_rgba(0,0,0,0.5)] tw-shadow-lg">
                {/* Modal Title */}
                {title && (
                    <h2 className="tw-mb-4 tw-text-center tw-text-[24px] tw-font-semibold tw-text-black">{title}</h2>
                )}
                {/* Modal Body */}
                <div className="tw-text-center tw-text-beige-beyond-beyond-dark">{children}</div>
            </div>
        </ModalBackdrop>
    );
}

export const FrontRoyalModal = memo(FrontRoyalModalComponent) as typeof FrontRoyalModalComponent;
