// Timer is tested in TimerSingleton.spec.ts
import { type Transaction as SentryTransaction } from '@sentry/types';

type TimerOptions = {
    debug: boolean;
    sentryTransaction?: SentryTransaction | null;
};

type Step = {
    eventType: string;
    duration: number;
};

export default class Timer {
    startTime: number;
    lastStepTime: number;
    steps: Step[] = [];
    debug: boolean;
    sentryTransaction: SentryTransaction | null;

    constructor({ debug, sentryTransaction }: TimerOptions) {
        this.startTime = Date.now();
        this.lastStepTime = Date.now();
        this.debug = debug;

        this.sentryTransaction = sentryTransaction || null;
    }

    addStep(step: Step) {
        this.steps.push(step);
    }

    afterFinish(finalEventType: string, totalDuration: number) {
        if (this.sentryTransaction) {
            this.sentryTransaction.finish();
        }
        if (this.debug) {
            console.log('----------------- ', finalEventType);
            this.steps.forEach(step => {
                console.log(step.duration, step.eventType);
            });
            console.log('total ', totalDuration);
            console.log('-----------------');
        }
    }
}
