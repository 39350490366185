function googlePlacesLoaded($injector) {
    const $window = $injector.get('$window');
    return !!($window.google && $window.google.maps && $window.google.maps.places);
}

function ensureGooglePlacesLoaded($injector) {
    const $q = $injector.get('$q');

    if (googlePlacesLoaded($injector)) {
        return $q.resolve();
    }

    const RouteAssetLoader = $injector.get('Navigation.RouteAssetLoader');
    return RouteAssetLoader.loadGooglePlacesDependencies();
}

function setupAutoComplete($injector, input, options) {
    const $window = $injector.get('$window');

    const autoCompleteOptions = {
        types: ['(cities)'], // restrict to only showing city, state, country options
        // componentRestrictions: {} // we could potentially restrict to US, etc
        ...options.autoCompleteOptions,
    };

    // if null is passed in for types, revert to the default
    // of allowing full address
    if (autoCompleteOptions.types === null) {
        delete autoCompleteOptions.types;
    }

    // create autocomplete component and register callback
    return new $window.google.maps.places.Autocomplete(input, autoCompleteOptions);
}

// We are bucket-brigading the input into the callback.  See MultiLocationInput
// to understand why this is helpful. In that case, it's actually kind of tricky
// for the component to get access to the input it created. Since we are setting up
// the ref with useCallback instead of useRef, it seems like we can't access the
// element with anything like locationInputRef.current.  So, we send it up here to
// give MultiLocationInput access to it.
function setupListener($injector, autocomplete, onPlaceChanged, input) {
    const $window = $injector.get('$window');

    const googleMapsListener = $window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const [place, placeDetails] = getPlaceAndDetails($injector, autocomplete);
        if (!place || !placeDetails) {
            return;
        }
        onPlaceChanged(place, placeDetails, input);
    });

    return function cancelListener() {
        $window.google.maps.event.removeListener(googleMapsListener);
    };
}

// See https://github.com/ocombe/ocLazyLoad/issues/170
// See https://gist.github.com/VictorBjelkholm/6687484
function getPlaceAndDetails($injector, autocomplete) {
    const ErrorLogService = $injector.get('ErrorLogService');
    const $rootScope = $injector.get('$rootScope');

    // get the auto-completed place info
    const place = autocomplete.getPlace();

    const placeDetails = {};

    // If the user enters a couple characters and presses enter without
    // selecting a place, this gets triggered with just {name: 'as'}
    // In that case, do nothing
    if (!place.place_id) {
        return [];
    }

    // See https://developers.google.com/maps/documentation/javascript/3.exp/reference#MarkerPlace
    // normalize the address_components for DB indexing
    if (place.address_components) {
        place.address_components.forEach(component => {
            placeDetails[component.types[0]] = {
                short: component.short_name,
                long: component.long_name,
            };
        });

        // pluck out the lat and lon from for matchmaking
        if (
            place &&
            place.geometry &&
            place.geometry.location &&
            place.geometry.location.lat &&
            place.geometry.location.lng
        ) {
            placeDetails.lat = place.geometry.location.lat();
            placeDetails.lng = place.geometry.location.lng();
        } else {
            ErrorLogService.notify('Place has no lat/lng', undefined, {
                user_id: $rootScope.currentUser.id,
                place_id: place && place.place_id,
            });
        }

        // this is convenient for display purposes, etc
        placeDetails.formatted_address = place.formatted_address;

        // in lieu of requiring a separate time-zone input ...
        placeDetails.utc_offset = place.utc_offset_minutes || place.utc_offset;

        // We started collecting this with student network events
        placeDetails.name = place.name;
        placeDetails.adr_address = place.adr_address;

        if (!place.place_id || _.isEmpty(placeDetails)) {
            ErrorLogService.notify('Missing either place_id or placeDetails', undefined, {
                user_id: $rootScope.currentUser.id,
            });
        }
    }

    return [place, placeDetails];
}

export default function attachGooglePlacesToInput({
    $injector,
    input,
    options,
    onGooglePlacesInitialized,
    onGooglePlacesFailedToLoad,
}) {
    options = options || {};
    onGooglePlacesInitialized = onGooglePlacesInitialized || function () {};
    let autocomplete;

    if (!$injector) {
        throw new Error('No injector provider.  You probably need to set up AngularContext.');
    }

    const promise = ensureGooglePlacesLoaded($injector).then(() => {
        onGooglePlacesInitialized();

        if (!googlePlacesLoaded($injector)) {
            if (onGooglePlacesFailedToLoad) {
                onGooglePlacesFailedToLoad();
                return;
            }

            throw new Error('Google Places API not loaded');
        }

        autocomplete = setupAutoComplete($injector, input, options);
    });

    // We return a function that allows for adding a listener on the onPlaceChanged event.
    // That function, in turn, returns a cancellation function.  This is extra complicated
    // because we don't want the user of this to have to worry about the asnychronicity introduced
    // by loading the google library (see comments inline).
    return function addListener(onPlaceChanged) {
        onPlaceChanged = onPlaceChanged || function () {};

        // If the google library is already loaded, and we have already attached google
        // places to the input, then things are simple.  We call setupListener, and that
        // returns a cancellation function
        if (autocomplete) {
            return setupListener($injector, autocomplete, onPlaceChanged, input);
        }

        // If we do not uet have an `autocomplete` object to listen on, then we need
        // to wait for google places to initialize.  But, the callback might get canceled
        // before the initialization is complete.  In that case, we need to prevent
        // setting up the listener before initialization completes.

        let canceledBeforeInitialized = false;
        let _cancelListener;
        promise.then(() => {
            if (canceledBeforeInitialized) {
                return;
            }
            if (autocomplete) {
                _cancelListener = setupListener($injector, autocomplete, onPlaceChanged, input);
            }
        });
        return function cancelListener() {
            if (_cancelListener) {
                _cancelListener();
            } else {
                canceledBeforeInitialized = true;
            }
        };
    };
}
