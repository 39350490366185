import MockHelpScoutBeacon from './MockHelpScoutBeacon';

const HELPSCOUT_BEACON_EMBA_ID = '1ef24f81-9517-4620-adda-1cd67704dc58';
const HELPSCOUT_BEACON_EMBASL_ID = 'a1c08853-3d44-4722-a850-3e0393147753';
const HELPSCOUT_BEACON_HYBRID_ID = 'a3df8a38-e8c3-49c2-b21f-59ff7c7dce33';
const HELPSCOUT_BEACON_MBALM_ID = '5ce89016-3861-4092-b26c-60accf2c1307';

export * from './HelpScoutBeacon.types';

export {
    MockHelpScoutBeacon,
    HELPSCOUT_BEACON_EMBA_ID,
    HELPSCOUT_BEACON_EMBASL_ID,
    HELPSCOUT_BEACON_HYBRID_ID,
    HELPSCOUT_BEACON_MBALM_ID,
};
