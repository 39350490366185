import { type ReactElement, type ReactNode, memo } from 'react';
import { type auto } from 'angular';
import { Provider } from 'react-redux';
import { AngularProvider } from 'AngularContext';
import { useTargetBrandConfig } from 'FrontRoyalAngular/useTargetBrandConfig';
import clsx from 'clsx';
import { type configureStore } from '@reduxjs/toolkit';

export function TailwindBaseContainer({
    children,
    targetTailwindBaseStyles,
    className,
}: {
    children: ReactNode;
    targetTailwindBaseStyles: boolean;
    className?: string;
}) {
    const targetBrandConfig = useTargetBrandConfig();
    return (
        // The .tailwind-base-container class is used as a target for the custom Tailwind CSS preflight styles.
        // if targetTailwindBaseStyles is passed as false, we won't clear the global styling.
        // This lets us keep using the global styles on react code that was made before switching to tailwind
        // FIXME: Those places should be updated and 'tailwind-base-container' should be enabled by default when possible
        <div
            className={clsx({ 'tailwind-base-container': targetTailwindBaseStyles }, className)}
            data-brand={targetBrandConfig.brandStyleClass}
        >
            {children}
        </div>
    );
}

type Props = {
    children: ReactElement;
    angularInjector: auto.IInjectorService;
    classNames?: string;
    targetTailwindBaseStyles?: boolean;
    store: ReturnType<typeof configureStore>;
};
const ReactWrapperComponent: React.FC<Props> = ({
    store,
    children,
    angularInjector,
    classNames,
    targetTailwindBaseStyles = true,
}) => (
    <AngularProvider injector={angularInjector}>
        <TailwindBaseContainer className={clsx(classNames)} targetTailwindBaseStyles={targetTailwindBaseStyles}>
            <Provider store={store}>{children}</Provider>
        </TailwindBaseContainer>
    </AngularProvider>
);

export const ReactWrapper = memo(ReactWrapperComponent) as typeof ReactWrapperComponent;
