
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_upload = window.Smartly.locales.modules.en.back_royal.front_royal_upload || {};
window.Smartly.locales.modules.en.back_royal.front_royal_upload.front_royal_upload = window.Smartly.locales.modules.en.back_royal.front_royal_upload.front_royal_upload || {};
window.Smartly.locales.modules.en.back_royal.front_royal_upload.front_royal_upload = {...window.Smartly.locales.modules.en.back_royal.front_royal_upload.front_royal_upload, ...{
    "that_file_is_not_valid_allowed_types_are": "Sorry, we don't accept that file type. Allowed types are {{allowed_types}}.",
    "invalid_file": "Invalid File Type",
    "that_file_is_not_valid": "Sorry, we don't accept that file type.",
    "max_size_exceeded": "Sorry, your upload exceeds the maximum file size: {{size}}.",
    "unknown_file_issue": "Sorry, there is a problem with the file you have selected."
}
}
    