import {
    type PublishedStream,
    type PublishedPlaylist,
    type StudentDashboardContentFetch,
} from './FrontRoyalStore.types';

export const stubPublishedStream: PublishedStream = {
    id: 'id',
    all_content_stored: 0,
    lessons: [],
    locale_pack: {
        id: 'locale_pack_id',
        content_items: [{ locale: 'en' }],
    },
    locale: 'en',
    image: { formats: {} } as PublishedStream['image'],
    schema_version: '42',
    title: '',
    chapters: [],
    exam: false,
    requires_proctoring: false,
    stream_lesson_tutor_bot_records: [],
    published_at: 42,
};

export const stubPublishedPlaylist: PublishedPlaylist = {
    id: 'id',
    locale_pack_id: 'locale_pack_id',
    locale_pack: { id: 'locale_pack_id', content_items: [{ locale: 'en' }] },
    stream_entries: [{ locale_pack_id: 'stream_locale_pack_id' }],
    title: 'title',
};

export const stubStudentDashboardContentFetch: StudentDashboardContentFetch = {
    user_id: 'user_id',
    cohort_id: 'cohort_id',
    cohort_version_id: 'cohort_version_id',
    pref_locale: 'pref_locale',
    favorite_lesson_stream_locale_pack_ids: ['locale_pack_id'],
    content_views_refresh_updated_at: 42,
};

export const stubStudentDashboardContentFetchKey = {
    user_id: stubStudentDashboardContentFetch.user_id,
    cohort_id: stubStudentDashboardContentFetch.cohort_id,
    cohort_version_id: stubStudentDashboardContentFetch.cohort_version_id,
    pref_locale: stubStudentDashboardContentFetch.pref_locale,
    content_views_refresh_updated_at: stubStudentDashboardContentFetch.content_views_refresh_updated_at,
};
