import fakeIndexedDb from 'fake-indexeddb';
import FDBKeyRange from 'fake-indexeddb/lib/FDBKeyRange';
import setGlobalVars from 'indexeddbshim/dist/indexeddbshim-noninvasive';
import { FAKE_INDEXED_DB, SQLITE_PLUGIN } from './supportedDbTechnology';

export default function getDexieConfig(dbTechnology) {
    if (!dbTechnology) {
        throw new Error('dbTechnology must be provided');
    }

    if (!dbTechnology && global.RUNNING_IN_TEST_MODE) {
        dbTechnology = FAKE_INDEXED_DB;
    }

    const dexieConfig = {};

    if (dbTechnology === FAKE_INDEXED_DB) {
        dexieConfig.indexedDB = fakeIndexedDb;
        dexieConfig.IDBKeyRange = FDBKeyRange;
    } else if (dbTechnology === SQLITE_PLUGIN) {
        // Create a `shim` object and tell indexeddbshim to put the relevant properties
        // on it to avoid polluting the global scope. Below, we will pass these
        // properties to Dexie so that Dexie will connect to SQLite.

        // https://github.com/indexeddbshim/IndexedDBShim#setglobalvarswinobj-or-null-initialconfig
        // For an explanation on the `win` argument, read through this:
        // https://github.com/indexeddbshim/IndexedDBShim/blob/80592387bc3662f028cb5b1094c3ad57b03f5444/src/CFG.js
        // Basically, since sqlitePlugin has the openDatabase function we want to use, that's
        // what we pass as `win`.
        const shim = {};
        setGlobalVars(shim, { win: window.sqlitePlugin /* DEBUG: true */ });

        dexieConfig.indexedDB = shim.indexedDB;
        dexieConfig.IDBKeyRange = shim.IDBKeyRange;
    } else {
        dexieConfig.indexedDB = window.indexedDB;
    }

    dexieConfig.dbTechnology = dbTechnology;
    return dexieConfig;
}
