import 'FrontRoyalStore/locales/FrontRoyalStore/showStorageQuotaExceededModal-en.json';
import { getBrandName } from 'AppBranding';

export default function showStorageQuotaExceededModal(injector) {
    const $rootScope = injector.get('$rootScope');
    const offlineModeManager = injector.get('offlineModeManager');
    const DialogModal = injector.get('DialogModal');
    const ConfigFactory = injector.get('ConfigFactory');
    const TranslationHelper = injector.get('TranslationHelper');
    const translationHelper = new TranslationHelper('FrontRoyalStore.showStorageQuotaExceededModal');

    let messageKey;
    if (offlineModeManager.inOfflineMode) {
        messageKey = 'FrontRoyalStore.showStorageQuotaExceededModal.in_offline_mode_message';
    } else {
        messageKey = 'FrontRoyalStore.showStorageQuotaExceededModal.not_in_offline_mode_message';
    }

    return new Promise(resolve => {
        DialogModal.alert({
            content: `
                <p class="center" translate-once="{{messageKey}}" translate-values="{brandName: brandNameShort}"></p>
                <p class="center">
                    <front-royal-spinner ng-if="proxy.retrying"></front-royal-spinner>
                    <button
                        class="flat green"
                        ng-if="!proxy.retrying"
                        ng-click="continue(proxy)"
                        translate-once="FrontRoyalStore.showStorageQuotaExceededModal.continue"
                    ></button>
                </p>
            `,
            title: translationHelper.get('title'),
            size: 'small',
            blurTargetSelector: 'div[ng-controller]',
            hideCloseButton: true,
            closeOnClick: false,
            indestructible: true,
            scope: {
                messageKey,
                proxy: { retrying: false },
                brandNameShort: getBrandName($rootScope.currentUser, ConfigFactory.getSync()),
                continue(proxy) {
                    proxy.retrying = true;

                    // We don't hide the modal now. The caller is responsible for that because
                    // they're the one that knows if more storage space has been made available.
                    // Here we just resolve the promise so that execution continues for the caller.
                    resolve();
                },
            },
        });
    });
}
