import { SvgPlay36 } from './Icons/Play36';
import { SvgSearch24 } from './Icons/Search24';
import { SvgClose16 } from './Icons/Close16';
import { SvgRightArrow16 } from './Icons/RightArrow16';
import { SvgChevronDown16 } from './Icons/ChevronDown16';
import { SvgClose24 } from './Icons/Close24';

export const GlobalIconComponents = {
    Play36: SvgPlay36,
    Search24: SvgSearch24,
    Close16: SvgClose16,
    RightArrow16: SvgRightArrow16,
    ChevronDown16: SvgChevronDown16,
    Close24: SvgClose24,
};
