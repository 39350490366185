import angularModule from 'FrontRoyalLinkedIn/angularModule/scripts/linkedin_module';
import template from 'FrontRoyalLinkedIn/angularModule/views/linkedin_profile_button.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('linkedinProfileButton', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const $window = $injector.get('$window');
        const EventLogger = $injector.get('EventLogger');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return {
            scope: {
                stream: '<',
            },
            restrict: 'E',
            templateUrl,
            controllerAs: 'controller',

            link(scope) {
                NavigationHelperMixin.onLink(scope);

                // The LinkedIn app seems to have broken adding certificates via a link; disabling this button in native context for now
                scope.showButton = !$window.CORDOVA && !scope.stream.exam;

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.logClick = () => {
                    const logInfo = scope.stream.logInfo();
                    EventLogger.log(
                        'share',
                        angular.extend(
                            {
                                label: 'add_to_profile',
                                service: 'linkedin',
                            },
                            logInfo,
                        ),
                    );
                };

                // We used to add extra parameters that would fill in parts of the form,
                // but linkedIn no longer supports it.
                scope.addLink = `https://www.linkedin.com/profile/add`;
            },
        };
    },
]);
