import clsx from 'clsx';
import { type SvgProps } from 'FrontRoyalIcon';

export function SvgChevronDown16(props: SvgProps) {
    const { className, ...rest } = props;
    const classes = clsx('svg-chevron-down-16', className);

    return (
        <svg width={16} height={16} viewBox="0 0 16 16" className={classes} {...rest}>
            <path
                d="M4.6 6.2a1 1 0 1 0-1.2 1.6l4 3a1 1 0 0 0 1.2 0l4-3a1 1 0 0 0-1.2-1.6L8 8.75 4.6 6.2z"
                fill="currentColor"
                fillRule="nonzero"
            />
        </svg>
    );
}
