import clsx from 'clsx';
import { type SvgProps } from 'FrontRoyalIcon';

export function SvgRightArrow16(props: SvgProps) {
    const { className, ...rest } = props;
    const classes = clsx('svg-right-arrow-16', className);

    return (
        <svg width={16} height={16} viewBox="0 0 16 16" className={classes} {...rest}>
            <path
                fill="currentColor"
                d="M7.293 3.293a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 1 1-1.414-1.414L9.584 9H3c-1.287 0-1.332-1.864-.133-1.993L3 7h6.586L7.293 4.707a1 1 0 0 1-.083-1.32z"
            />
        </svg>
    );
}
