const matchExpressions = [
    /Connection to Indexed Database server lost/,
    /Internal error opening backing store for/,
    /unable to open database file/,
    /disk I\/O error/,
];

export default function isErrorFixedByRefresh(error: Error) {
    if (!error.message) return false;

    return matchExpressions.some(exp => error.message.match(exp));
}
