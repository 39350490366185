// This module is the reason for the  no-restricted-imports rule, so we need to ignore it only in this one file
/* eslint-disable no-restricted-imports */
/* eslint-disable quantic/no-type-exports */

/**
 * This module "FrontRoyalReactHookForm" is a re-export of the standard "react-hook-form" library, except
 * that we decided we want to change the default options for the "setValue" function that exists on the
 * useForm and useFormContext hooks. We have decided that we want the shouldValidate and shouldDirty options to default to true.
 * Without this module we would need to pass { shouldValidate: true, shouldDirty: true } in every setValue call from these two hooks.
 *
 * We have kept the original api and ability to overwrite these changes the same, but for clarity, we renamed the function from
 * "setValue" to "setFieldValue"
 *
 * @example if you did need to overwrite our updated settings
 *
 *  1. const { setFieldValue } = useFormContext();
 *  2. setFieldValue('nameOfFormField', newValue, { shouldValidate: false, shouldDirty: false })
 *
 */
import { useCallback } from 'react';
import {
    useForm as useRHFForm,
    useFormContext as useRHFFormContext,
    FormProvider as RHHFFormProvider,
    type FieldValues,
    type Path,
    type UseFormProps,
    type UseFormReturn as RHFUseFormReturn,
    type FormProviderProps as RHHFFormProviderProps,
} from 'react-hook-form';

export * from 'react-hook-form';

export { yupResolver } from '@hookform/resolvers/yup';

export type UseFormReturn<TFieldValues extends FieldValues = FieldValues, TContext = unknown> = Omit<
    RHFUseFormReturn<TFieldValues, TContext>,
    'setValue'
> & { setFieldValue: RHFUseFormReturn<TFieldValues, TContext>['setValue'] };

export const useForm = <TFieldValues extends FieldValues = FieldValues, TContext = unknown>(
    props?: UseFormProps<TFieldValues, TContext>,
): UseFormReturn<TFieldValues, TContext> => {
    const { setValue, ...rest } = useRHFForm<TFieldValues>(props);

    const setFieldValue: typeof setValue = useCallback(
        (
            name: Path<TFieldValues>,
            value: TFieldValues[typeof name],
            options = { shouldValidate: true, shouldDirty: true, shouldTouch: true },
        ) => setValue(name, value, options),
        [setValue],
    );

    return { ...rest, setFieldValue };
};

export const useFormContext = useRHFFormContext as unknown as <TFieldValues extends FieldValues>() => UseFormReturn<
    TFieldValues,
    unknown
>;

export type FormProviderProps<TFieldValues extends FieldValues = FieldValues, TContext = unknown> = Omit<
    RHHFFormProviderProps<TFieldValues, TContext>,
    'setValue'
> & { setFieldValue: RHFUseFormReturn<TFieldValues, TContext>['setValue'] };

export const FormProvider = RHHFFormProvider as unknown as <TFieldValues extends FieldValues, TContext = unknown>(
    props: FormProviderProps<TFieldValues, TContext>,
) => JSX.Element;
