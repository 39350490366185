import 'RouteAnimationHelper/angularModule';
import 'Injector/angularModule';
import cacheAngularTemplate from 'cacheAngularTemplate';
import ngViewTemplate from 'FrontRoyalWrapper/angularModule/views/ng_view.html';

// Navigation.AppHeader.AppHeaderViewModel is also a dependency here
const frontRoyalWrapperModule = angular.module('FrontRoyal.Wrapper', ['FrontRoyal.RouteAnimationHelper', 'Injector']);
cacheAngularTemplate(frontRoyalWrapperModule, 'FrontRoyalWrapper/ng_view.html', ngViewTemplate);

export default frontRoyalWrapperModule;
