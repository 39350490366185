import type FrontRoyalStore from './FrontRoyalStore';

/*
    When running the app normally in the wild, there is one global instance of
    FrontRoyalStore. We keep track of that instance in the `frontRoyalStore` angular
    factory, the way we normally do with Singletons in angular.

    However, we also need to be able to access the store from the frontRoyalStoreApi. That
    code cannot access the angular injector, and there is no way for us to pass the injector
    to it, because we're working through redux, which only stores vanilla javascript objects.

    The frontRoyalStoreProvider solves this problem by providing another way to access the
    global instance of the FrontRoyalStore.
*/
export default {
    frontRoyalStore: null as FrontRoyalStore | null,
    get() {
        return this.frontRoyalStore;
    },

    set(val: FrontRoyalStore | null) {
        this.frontRoyalStore = val;
    },
};
