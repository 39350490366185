import { createPortal } from 'react-dom';
import { type ReactNode } from 'react';
import { FrontRoyalModal } from './FrontRoyalModal';

// Overload 1: With title
export function createModal(title: string, content: ReactNode): ReturnType<typeof createPortal>;

// Overload 2: Without title
export function createModal(content: ReactNode): ReturnType<typeof createPortal>;

// Function Implementation
export function createModal(titleOrContent: string | ReactNode, content?: ReactNode): ReturnType<typeof createPortal> {
    // Check if the first argument is a string (the title) or content
    const title = typeof titleOrContent === 'string' ? titleOrContent : undefined;
    const modalContent = content || titleOrContent;

    return createPortal(<FrontRoyalModal title={title}>{modalContent}</FrontRoyalModal>, document.body);
}
