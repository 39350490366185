import cacheAngularTemplate from 'cacheAngularTemplate';
import template from '../views/front_royal_wrapper.html';
import angularModule from './front_royal_wrapper_module';

// NOTE: we're seeing infrequent errors with this template not being populated in $templateCache
// so we are logging with a provided name for Sentry fingerprinting and consistent metrics.
const templateUrl = cacheAngularTemplate(angularModule, 'front_royal_wrapper.html', template);

angularModule.directive('frontRoyalWrapper', [
    '$injector',
    function factory($injector) {
        const RouteAnimationHelper = $injector.get('RouteAnimationHelper');
        const $timeout = $injector.get('$timeout');
        const $route = $injector.get('$route');
        const $translate = $injector.get('$translate');

        return {
            restrict: 'E',
            scope: {},
            templateUrl,

            link(scope) {
                scope.routeAnimationClasses = () => RouteAnimationHelper.cssClasses;

                // Show a spinner while route resolvers are doing their
                // thing
                let resolvingRouteTimeout;

                // Note: we used to watch $routeChangeStart and $routeChangeSuccess here, but found
                // that they sometimes come out of order
                scope.$route = $route;
                scope.$watch('$route.frontRoyalIsResolvingRoute', frontRoyalIsResolvingRoute => {
                    $timeout.cancel(resolvingRouteTimeout);
                    frontRoyalIsResolvingRoute = frontRoyalIsResolvingRoute || false;
                    const oldVal = scope.resolvingRouteTakingSomeTime || false;

                    /*
                        scope.resolvingRouteTakingSomeTime is basically a debounced version
                        of frontRoyalIsResolvingRoute.  If it takes a while to resolve
                        the route, we use this value to switch on a spinner.

                        For a long time, we only had the delay here when switching from
                        false to true.  When adding the "You are offline" modal, I had
                        to add the delay as well when going from true to false, in order to
                        prevent seeing a flash of old content after dismissing the modal.
                    */
                    if (frontRoyalIsResolvingRoute !== oldVal) {
                        resolvingRouteTimeout = $timeout(() => {
                            scope.resolvingRouteTakingSomeTime = frontRoyalIsResolvingRoute;
                        }, 200);
                    }
                });

                scope.$on('$locationChangeSuccess', () => {
                    scope.useShell = $route.current.useShell !== false;
                });

                $translate.onReady(() => {
                    scope.translationsReady = true;
                });
            },
        };
    },
]);
