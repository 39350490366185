import clsx from 'clsx';
import { type SvgProps } from 'FrontRoyalIcon';

export function SvgClose16(props: SvgProps) {
    const { className, ...rest } = props;
    const classes = clsx('svg-close-16', className);

    return (
        <svg width={16} height={16} viewBox="0 0 16 16" className={classes} {...rest}>
            <g fill="currentColor" fillRule="evenodd">
                <path d="M12.447 2.184a.965.965 0 0 1 1.369 0 .972.972 0 0 1 0 1.369L3.553 13.816a.965.965 0 0 1-1.369 0 .972.972 0 0 1 0-1.369L12.447 2.184z" />
                <path d="M2.184 2.184a.972.972 0 0 1 1.369 0l10.263 10.263a.965.965 0 0 1 0 1.369.972.972 0 0 1-1.369 0L2.184 3.553a.965.965 0 0 1 0-1.369z" />
            </g>
        </svg>
    );
}
