import { FlatButton } from 'FlatButton';
import { memo } from 'react';

const MessageAndButtonModalComponent = ({
    message,
    buttonText,
    onClick,
}: {
    message: string;
    buttonText: string;
    onClick: () => void;
}) => (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-5">
        <div>{message}</div>
        <FlatButton className="tw-bg-green tw-text-center" onClick={() => onClick()}>
            {buttonText}
        </FlatButton>
    </div>
);

export const MessageAndButtonModal = memo(MessageAndButtonModalComponent) as typeof MessageAndButtonModalComponent;
