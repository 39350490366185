import { type Exact, type AnyObject, type Nullable } from '@Types';
import { EventEmitter } from 'events';
import { type auto } from 'angular';
import { type ErrorLogService as ErrorLogServiceClass } from 'ErrorLogging';
import { type ConfigFactory as ConfigFactoryType } from 'FrontRoyalConfig';
import { type OfflineModeManager } from 'OfflineMode';
import { type DialogModal } from 'DialogModal';
import { type CurrentUserIguanaObject } from 'Users';
import { type EventLogger } from 'EventLogger';
import { logTinyEvent } from 'TinyEventLogger';
import { storageSpaceAvailable } from 'storageSpaceAvailable';
import promiseTimeout from 'promiseTimeout';
import frontRoyalStoreApi from './frontRoyalStoreApi';
import openDb from './openDb';
import flushStore, { flushRemovableItemsFromStore, hasUnflushedData } from './flushStore';
import { FAKE_INDEXED_DB, INDEXED_DB } from './supportedDbTechnology';
import isQuotaExceededError from './isQuotaExceededError';
import isErrorFixedByRefresh from './isErrorFixedByRefresh';
import showStorageQuotaExceededModal from './showStorageQuotaExceededModal';
import type FrontRoyalStoreDB from './FrontRoyalStoreDB';
import { type FrontRoyalStoreDBTableNames } from './FrontRoyalStoreDB';
import { type ConfigRecord, type CurrentUserRecord } from './FrontRoyalStore.types';
import recoverFromVersionError from './recoverFromVersionError';

export default class FrontRoyalStore extends EventEmitter {
    private initializeDbPromise: Nullable<Promise<FrontRoyalStoreDB>> = null;
    injector: auto.IInjectorService;
    _fatalErrorPromise: Promise<unknown> | undefined;
    _handleQuotaExceededErrorPromise: Promise<unknown> | undefined;
    _flushPromises: Promise<boolean>[];

    constructor(injector: auto.IInjectorService) {
        super();
        this.injector = injector;
        this._flushPromises = [];
        ensureLoggingUnhandledRejections(this.injector);
    }

    getDb() {
        if (!this.initializeDbPromise) {
            this.initializeDbPromise = this.initializeDb();
        }
        return this.initializeDbPromise;
    }

    async getDbTechnology() {
        const db = await this.getDb();
        return db.dbTechnology;
    }

    getCurrentUser() {
        return this.getSingleRecord('currentUsers') as Promise<CurrentUserRecord>;
    }

    getConfig() {
        return this.getSingleRecord('configRecords') as Promise<ConfigRecord>;
    }

    async getSingleRecord(table: Extract<FrontRoyalStoreDBTableNames, 'currentUsers' | 'configRecords'>) {
        // This can be called even when the store is not enabled,
        // since we need to load up config and user during initialization before
        // we have authenticated a current user. For that reason,
        // `db` might be null inside of the block, so we need a null
        // check.
        return this.retryOnHandledError(
            async db => {
                if (!db) return null;

                const records = await db[table].toArray();
                if (records.length > 1) {
                    const ErrorLogService = this.injector.get<typeof ErrorLogServiceClass>('ErrorLogService');
                    ErrorLogService.notifyInProd(`There are multiple records in the ${table} table`, null, {
                        extra: {
                            ids: records.map(r => r.id),
                        },
                    });
                    await db[table].clear();
                    return undefined;
                }
                return records[0];
            },
            {
                // To understand why we're using allowDisabledFrontRoyalStore here, see comment at the top of this
                // function about this method being called when the store is disabled
                allowDisabledFrontRoyalStore: true,
                name: `getSingleRecord(${table})`,
            },
        );
    }

    async storeCurrentUser(record: CurrentUserRecord) {
        if (!record) return undefined;

        return this.retryOnHandledError(db => db.currentUsers.put(record));
    }

    async updateCurrentUser(currentUserId: string, updatedProperties: Partial<CurrentUserRecord>) {
        if (!currentUserId || !updatedProperties) return undefined;

        return this.retryOnHandledError(db => db.currentUsers.update(currentUserId, updatedProperties));
    }

    destroy() {
        // We used to call removeAllListeners here.  For now this is no longer
        // necessary, but leaving this instead of going and removing this from
        // whatever specs are calling it.  If we still don't need this
        // after all this code settles down more, we can consider removing it.
    }

    /*
        Gets the client db and invokes the given callback function, `fn`, passing along the client db an argument.
        If the invoked callback function throws an error, FrontRoyalStore will try to handle it. If it can be handled,
        in most cases, the callback function will be retried without error handling. If the retry attempt fails, the error
        will bubble up. `QuotaExceededError`s are the exception. `QuotaExceededError`s have their own special handling
        (see _handleQuotaExceededError).

        @param `fn` - Callback function to execute. Receives the client db as its only argument.
        @param `opts` (optional) - An options object that can be used to control the way errors are handled.
            Supported properties:
                `errorHandlingPolicies` - An object that controls how `retryOnHandledError` handles errors.
                    Supported props:
                        `throwOnStorageQuotaExceededError` - By default, `retryOnHandledError` will gracefully
                            handle `QuotaExceededError`s. Setting this to true will force them to be thrown.
                `allowDisabledFrontRoyalStore` - By default, an error will be thrown if `retryOnHandledError` is called
                    when the store is disabled. Setting this to `allowDisabledFrontRoyalStore` to `true` will
                    override this.
    */
    async retryOnHandledError<T>(
        fn: (db: FrontRoyalStoreDB) => T,
        opts: {
            name?: string;
            allowDisabledFrontRoyalStore?: boolean;
            errorHandlingPolicies?: AnyObject;
            retryCount?: number;
        } = {},
    ): Promise<T> {
        const retryCount = opts.retryCount || 0;

        // If the function passed in here is async, then toString() on it will not help us to identify it.
        // In those cases, we pass in a name just for this debugging
        const handler = opts.name || fn.toString();

        opts.errorHandlingPolicies = {
            throwOnStorageQuotaExceededError: false,
            ...(opts.errorHandlingPolicies || {}),
        };

        // If we're showing the fatal error modal, we want to effectively "freeze" the app,
        // so we halt further attempts to call into retryOnHandledError so that things just
        // hang indefinitely, forcing the user to refresh the page.
        if (this._fatalErrorPromise) {
            return this._fatalErrorPromise as Promise<T>;
        }

        let db;
        try {
            db = await this.getDb();
            const returnValue = await fn(db);
            return returnValue;
        } catch (e) {
            try {
                const ErrorLogService = this.injector.get<typeof ErrorLogServiceClass>('ErrorLogService');
                const EventLogger = this.injector.get<EventLogger>('EventLogger');
                const error = e as Error;
                const message = error?.message || 'Unknown error';
                const logParams = {
                    label: message,
                    message,
                    error: error?.name,
                    handler,
                    quota_exceeded_error: isQuotaExceededError(error),
                    params: opts.errorHandlingPolicies,
                };

                // If we're having trouble connecting to IndexedDB,
                // then our normal error logging is not going to work. For that reason, we use logTinyEvent here.
                this.logTinyEvent('front_royal_store:caught_error', logParams);

                if (isQuotaExceededError(error) && !opts.errorHandlingPolicies.throwOnStorageQuotaExceededError && db) {
                    return await this._handleQuotaExceededError(e, fn, db);
                }

                // These errors are hopefully rare. They seem to happen sometimes after a tab has been idle for a while and
                // the user comes back. I think they are browser bugs that hopefully will be fixed in the future. We're logging these
                // to sentry now so we can monitor them.
                if (isErrorFixedByRefresh(error)) {
                    ErrorLogService.notify('Forcing refresh to fix FrontRoyalStore error', error);
                    return this._showFatalError(`error that should be fixed by refresh: ${error.message}`) as T;
                }

                if (this.shouldRetryError(error, db, retryCount)) {
                    const result = await this.retryOnHandledError(fn, {
                        ...opts,
                        retryCount: retryCount + 1,
                    });

                    // Since it seems like we're in business, it should be safe to use the regular
                    // event logger instead of logTinyEvent
                    EventLogger.log('front_royal_store:successful_retry', logParams);

                    return result;
                }

                /*
                Calling notify here seems wrong, but also necessary. In local testing, the error thrown here does
                not get logged to sentry. So, if we want to add additional information to it (the handler), then
                we need to explicitly call `notify`.

                In local testing, this error was actually already logged to sentry, apparently because Dexie explicitly
                triggered an unhandledexception event that sentry was listening for. This is annoying though, because
                it means there's no way for us to catch the error and either handle it or add additional information.

                So, adding notify here could mean that we see Dexie errors duplicated in the wild.
                In any case, we'll add this for now and see how it goes in the wild.
            */
                ErrorLogService?.notify?.(error, null, { handler });
            } catch (error) {
                const errorFromErrorHandling = error as Error;
                console.error(error);
                // If we error in during our error handling we want to show a fatal error instead of letting it bubble
                return this._showFatalError(
                    `Error handling errors in retryOnHandledError: ${errorFromErrorHandling?.message}`,
                ) as T;
            }

            console.error(e);
            throw e;
        }
    }

    retryRequestOnHandledError<
        EndpointName extends keyof typeof frontRoyalStoreApi['endpoints'],
        Params extends Exact<typeof frontRoyalStoreApi['endpoints'][EndpointName]['Types']['QueryArg'], Params>,
    >(...args: Params extends void ? [EndpointName, AnyObject?] : [EndpointName, Params, AnyObject?]) {
        const opts: AnyObject = args.length === 3 ? (args.pop() as AnyObject) : {};

        return this.retryOnHandledError(
            () =>
                frontRoyalStoreApi.makeRequest<EndpointName, Params>(
                    ...(args as Parameters<typeof frontRoyalStoreApi.makeRequest>),
                ),
            {
                name: args[0],
                ...(opts || {}),
            },
        );
    }

    // When I was testing this in October 2023, I ran into a problem where the call to deleteContentStoredForOfflineMode also
    // errors on a QuotaExceededError, and then we get stuck because of the way we're queuing up behind _handleQuotaExceededErrorPromise.
    // I can't tell if this happens in the wild or if it's just because of the way we're hacking the Quota with
    // devtools. My plan is to add a query to look for it. See https://trello.com/c/u3iGADEn
    _handleQuotaExceededError<T>(err: unknown, fn: (db: FrontRoyalStoreDB) => T, db: FrontRoyalStoreDB) {
        if (this._handleQuotaExceededErrorPromise) {
            this._handleQuotaExceededErrorPromise = this._handleQuotaExceededErrorPromise.then(() =>
                // Another process running in parallel could have triggered its own QuotaExceededError.
                // After we finish handling the first QuotaExceededError, immediately retry the closure
                // for process that was running in parallel.
                this._retryFnThatTriggeredQuotaExceededError(fn, db, false),
            );
        } else {
            this._handleQuotaExceededErrorPromise = this.__handleQuotaExceededError(err, fn, db);
        }
        return this._handleQuotaExceededErrorPromise as T;
    }

    // We have some fairly complex and intricate handling for QuotaExceededErrors. When we detect
    // that a QuotaExceededError has been thrown, we show the user a blocking modal with a message
    // saying that they have to free up more space in order to continue using the app. This could
    // happen when the user is in offline mode (e.g. when storing lesson progress) or outside of
    // offline mode (e.g. when storing content for offline use). The user is free to click a button
    // on the modal to continue, at which point we'll retry the failed closure. If it succeeds,
    // we'll dismiss the modal and the user can continue using the app. If it continues to fail
    // with a QuotaExceededError, we'll continue to block the UI with the modal.
    async __handleQuotaExceededError<T>(
        error: unknown,
        fn: (db: FrontRoyalStoreDB) => T,
        db: FrontRoyalStoreDB,
        storedContentHasBeenRemoved = false,
    ) {
        storedContentHasBeenRemoved = await this._tryToClearUpSpaceAfterQuotaExceededError(
            storedContentHasBeenRemoved,
            error,
            fn,
        );
        return this._retryFnThatTriggeredQuotaExceededError(fn, db, storedContentHasBeenRemoved);
    }

    async _tryToClearUpSpaceAfterQuotaExceededError<T>(
        storedContentHasBeenRemoved: boolean,
        error: unknown,
        fn: (db: FrontRoyalStoreDB) => T,
    ) {
        const ErrorLogService = this.injector.get<typeof ErrorLogServiceClass>('ErrorLogService');
        const offlineModeManager = this.injector.get<OfflineModeManager>('offlineModeManager');

        let availableSpace = await storageSpaceAvailable();

        // If there's no more space, we can't save events, so log with logTinyEvent
        this.logTinyEvent('front_royal_store:clear_space', {
            in_offline_mode: offlineModeManager.inOfflineMode,
            stored_content_has_been_removed: storedContentHasBeenRemoved,
            storage_space_available: availableSpace,
        });

        if (offlineModeManager.inOfflineMode) {
            await showStorageQuotaExceededModal(this.injector);

            // The user may have gone back online after seeing the modal, so we proactively
            // move them out of offline mode if we detect that they should no longer be in it.
            await offlineModeManager.updateInOfflineMode();
        } else {
            const logAndShowStorageQuotaExceededModal = async (err: unknown) => {
                ErrorLogService.notify(err as Error, fn, { level: 'warning' });
                this.logTinyEvent('front_royal_store:show_storage_quota_exceeded_modal', {
                    storage_space_available: availableSpace,
                });
                await showStorageQuotaExceededModal(this.injector);
            };

            // If we've already removed content from the store, but we still got a QuotaExceededError,
            // there's very little space left on the device, to the point where the app essentially can't
            // function properly without more storage space. So we log the error to Sentry so that
            // we can get an idea of how frequently this is happening and
            // then we show the storage quota exceeded modal to let the user know that they need to free
            // up more space on their device. The user can retry the closure, but they'll keep seeing this
            // modal if more QuotaExceededErrors pop up.
            if (storedContentHasBeenRemoved) await logAndShowStorageQuotaExceededModal(error);

            // If abortStoringContentAndDelete throws an error here, it will bubble up and cause the ui
            // to eventually lock up without any clarity. So we catch those errors and trigger the QuotaExceededModal,
            // which is clearer and gives the user a chance to recover in case the QuotaExceededError is resolved
            await offlineModeManager.abortStoringContentAndDelete().catch(logAndShowStorageQuotaExceededModal);

            availableSpace = await storageSpaceAvailable();
            this.logTinyEvent('front_royal_store:space_cleared', {
                in_offline_mode: offlineModeManager.inOfflineMode,
                storage_space_available: availableSpace,
            });

            storedContentHasBeenRemoved = true;
        }

        await this.flushRemovableItemsFromStore();

        return storedContentHasBeenRemoved;
    }

    async _retryFnThatTriggeredQuotaExceededError<T>(
        fn: (db: FrontRoyalStoreDB) => T,
        db: FrontRoyalStoreDB,
        storedContentHasBeenRemoved: boolean,
    ): Promise<T> {
        const DialogModal = this.injector.get<DialogModal>('DialogModal');
        const ErrorLogService = this.injector.get<typeof ErrorLogServiceClass>('ErrorLogService');

        // Now that we've cleared up some space, retry the failed closure. If it continues to fail with
        // a QuotaExceededError, we send them back through this UX for handling QuotaExceededErrors until
        // the failed closure is successful.
        try {
            const resolvedValue = await fn(db);

            // The retry was successful, so hide the storage quota exceeded modal. It kinda sucks that we don't
            // have a way to say "remove THIS modal" rather than "remove whatever modal is currently open",
            // but the storage quota exceeded modal is indestructible, so this should be fine.
            DialogModal.removeAlerts(true);

            return resolvedValue;
        } catch (err) {
            if (isQuotaExceededError(err)) {
                return this.__handleQuotaExceededError(err, fn, db, storedContentHasBeenRemoved);
            }

            // If we're catching an error here, then it's an unexpected error that we're not prepared
            // to handle, so we log to Sentry and show the fatal error dialog modal forcing the user to refresh.
            ErrorLogService.notify(err as Error, fn);
            return this._showFatalError('quota exceeded handling') as T;
        }
    }

    _showFatalError(label: string) {
        this.logTinyEvent('front_royal_store:show_fatal_error', { label });
        this._fatalErrorPromise = new Promise(() => {
            this.injector.get<DialogModal>('DialogModal').showFatalError();
        });
        return this._fatalErrorPromise;
    }

    setStreamBookmarks(user: CurrentUserIguanaObject) {
        if (!user) return Promise.resolve;

        return this.retryRequestOnHandledError('setStreamBookmarks', {
            userId: user.id,
            newLocalePackIds: user.favorite_lesson_stream_locale_packs.map(locale_pack => locale_pack.id),
        });
    }

    async hasUnflushedData() {
        return hasUnflushedData(this.injector);
    }

    get flushing() {
        return this._flushPromises.length > 0;
    }

    treatAsBackgroundRequest(requestConfig: angular.IRequestConfig) {
        try {
            return JSON.parse(requestConfig.data.get('meta'))?.flushing_front_royal_store || false;
        } catch (e) {
            return false;
        }
    }

    async flush() {
        // We need the _flushPromises list because they can get queued up behind each other.
        // If a flush is already in progress, wait until it's done before trying again.
        const promise = Promise.all(this._flushPromises).then(() => flushStore(this.injector));

        this._flushPromises.push(promise);
        // flushStore will return true if it succeeds in flushing,
        // false if we are in offlineMode
        return promise.finally(() => {
            this._flushPromises = this._flushPromises.filter(p => p !== promise);
        });
    }

    async _flushOrEnterOfflineMode() {
        const offlineModeManager = this.injector.get<OfflineModeManager>('offlineModeManager');
        const flushed = await this.flush();
        const inOfflineMode = flushed
            ? offlineModeManager.inOfflineMode
            : await offlineModeManager.updateInOfflineMode();

        return { flushed, inOfflineMode };
    }

    // Retry flush until it succeeds or the user enters offline mode,
    // using an exponential backoff
    // Returns both
    // * a promise that resolves to true if the flush succeeded and false if not (in which case the user is in offline mode)
    // * an abort function
    waitForFlushedOrEnterOfflineMode(
        { delayOnFailure, controller }: { delayOnFailure: number; controller?: AbortController } = {
            delayOnFailure: 500,
        },
    ): {
        promise: Promise<boolean>;
        abort: AbortController['abort'];
    } {
        // AbortController is a standard way to set up cancelable promises. See https://developer.mozilla.org/en-US/docs/Web/API/AbortController
        controller = controller || new AbortController();
        const signal = controller.signal;

        const promise = this._flushOrEnterOfflineMode().then(({ flushed, inOfflineMode }) => {
            if (flushed) {
                return true;
            }
            if (signal.aborted || inOfflineMode) {
                return false;
            }
            const nextDelay = Math.min(delayOnFailure * 2, 5000);
            return promiseTimeout(delayOnFailure).then(() => {
                const ensureFlushReturn = this.waitForFlushedOrEnterOfflineMode({
                    delayOnFailure: nextDelay,
                    controller,
                });
                return ensureFlushReturn.promise;
            });
        });

        return { promise, abort: controller.abort.bind(controller) };
    }

    async flushRemovableItemsFromStore() {
        // flushStore will return true if it succeeds in flushing,
        // false if we are in offlineMode
        return flushRemovableItemsFromStore(this.injector);
    }

    beforeUnsettingCurrentUser() {
        return this._flusheThenClearUserSpecificTables();
    }

    async getDataUrlIfStored(url: string, cacheForever = false) {
        const endpointName = cacheForever ? 'getStorableImageAndCacheForever' : 'getStorableImage';

        return this.retryRequestOnHandledError(endpointName, url);
    }

    async getStoredImageIntoTemporaryCache(url: string) {
        const urlOrDataUrl = await this.getDataUrlIfStored(url, false);

        type UrlOrBlob = string | { data: Blob };

        // If the image is available in the database, then getDataUrlIfStored will
        // return a data url and have the side effect of getting that data url into the
        // FrontRoyalStoreApi RAM cache. Otherwise, it will return the original url.
        const dataUrlIsNowCached = (urlOrDataUrl as UrlOrBlob) !== (url as UrlOrBlob);

        return dataUrlIsNowCached;
    }

    async flushThenClearAllTables() {
        await this.flush();
        await this.clearAllTables();
    }

    async _flusheThenClearUserSpecificTables() {
        await this.flush();
        await this.clearUserSpecificTables();
    }

    async clearAllTables() {
        await this.retryRequestOnHandledError('clearAllTables', { allowDisabledFrontRoyalStore: true });
    }

    async clearUserSpecificTables() {
        await this.retryRequestOnHandledError('clearUserSpecificTables', {
            allowDisabledFrontRoyalStore: true,
        });
    }

    async reinitializeDb() {
        await this.clearUserSpecificTables();

        // The only non-user-specific table is the configRecords table (see definition of clearUserSpecificTables in
        // frontRoyalStoreApi). We clear all the user-specific tables and then reload the config table
        const ConfigFactory = this.injector.get<ConfigFactoryType>('ConfigFactory');
        await ConfigFactory.reloadFromApi();
    }

    private async initializeDb() {
        let supportedDbTechnologyOverride = null;

        // For some reason we don't totally understand, trying to inject ConfigFactory at this point
        // errors. We don't care about `even_on_web` in cordova, so we skip over it there.
        if (!window.CORDOVA) {
            const ConfigFactory = this.injector.get<ConfigFactoryType>('ConfigFactory');
            const config = ConfigFactory.getSync(true);
            supportedDbTechnologyOverride = config?.enable_offline_mode === 'even_on_web' ? INDEXED_DB : null;
        }

        let db = await openDb(supportedDbTechnologyOverride);
        try {
            // In order to check that we can really use IndexedDB, we need to make a request to it.
            // This will error, for example, in FF private browsing mode. In that case, we fall back
            // to FAKE_INDEXED_DB
            await db.validDbPings.count();
        } catch (e) {
            const error = e as { name: string; message: string };

            const recoverPromise = recoverFromVersionError(error, db.dbTechnology);
            if (recoverPromise) {
                db = await recoverPromise;
                await db.validDbPings.count();
                return db;
            }

            if (error?.name === 'DatabaseClosedError' && !error?.message?.includes('UpgradeError')) {
                db = await openDb(FAKE_INDEXED_DB);

                // We excluded Dexie.UpgradeError because we confused ourselves when a migration was failing. There're
                // lots of different types of DatabaseClosedError, and there could also be another error we're interested in,
                // so it's hard to make a list either way. Let's at least warn in develoment when we're falling back to the
                // in-memory FAKE_INDEXED_DB.
                if (window.preloadedConfig?.app_env_name === 'development') {
                    // eslint-disable-next-line no-console
                    console.warn('Falling back to FAKE_INDEXED_DB', e);
                }
            } else {
                throw e;
            }
        }
        this.logOpenedDbEvent(db.dbTechnology);

        return db;
    }

    private logOpenedDbEvent(dbTechnology: string) {
        const EventLogger = this.injector.get<EventLogger>('EventLogger');
        EventLogger.log('front_royal_store:opened_db', {
            label: dbTechnology,
            db_technology: dbTechnology,
        });
    }

    private shouldRetryError(error: Error, db: FrontRoyalStoreDB | undefined, retryCount: number) {
        // retryCount of 2 means that we've tried 3 times, including the first try
        if (retryCount === 2) return false;
        if (!db) return false;

        // https://trello.com/c/NXJfSpCP. Don't really understand this one, but we've seen it work on a retry
        if (error.message?.match(/Attempt to iterate a cursor that doesn't exist/)) return true;

        // If a transaction stays open for a long time, for example when a browser loses focus, then it can
        // be aborted. On the retry we'll open a new transaction and it should work.
        if (error.message?.match(/transaction is inactive or finished/g)) return true;
        if (error.message?.match(/transaction has finished/g)) return true;
        if (error.name === 'AbortError') return true;
        if (error.name === 'TimeoutError') return true;

        return false;
    }

    private logTinyEvent(eventType: string, logParams: AnyObject) {
        logTinyEvent(eventType, logParams, {
            serverTimeOrConfigFactory: this.injector.get('ConfigFactory'),
        });
    }
}

let loggingUnhandledRejections = false;
function ensureLoggingUnhandledRejections(injector: angular.auto.IInjectorService) {
    if (loggingUnhandledRejections) return;

    // FIXME: This can't be right. The first error below shows
    // up in the console even if I don't do this.  The second one, though,
    // requires this (This code goes in StoredContent#toggleAvailability):
    //
    // See https://stackoverflow.com/questions/58398215/why-are-errors-in-a-dexie-promise-resolution-not-shown
    /*
        new Promise((resolve, reject) => {
            resolve();
        }).then(() => {
            throw new Error('vanilla promise');
        });

        const query = db.streams.where('id').equals(stream.id);

        return query.delete()
            .then(() => {
                throw new Error('dexie callback');
            });
    */
    const recentLogTimes: number[] = [];
    window.addEventListener('unhandledrejection', ev => {
        const message = ev?.reason?.message || 'Unknown rejection';
        // eslint-disable-next-line no-console
        console.error('unhandledrejection', message);

        // If we've already logged 10 unhandled rejections in the last minute, then throttle and do not
        // log this one.
        const oneMinute = 60000;
        if (recentLogTimes.length === 10 && Date.now() - recentLogTimes[0] < oneMinute) {
            return;
        }

        recentLogTimes.push(Date.now());
        if (recentLogTimes.length > 10) {
            recentLogTimes.shift();
        }

        // If we're having trouble connecting to IndexedDB,
        // then our normal error logging is not going to work. For that reason, we use logTinyEvent here.
        logTinyEvent(
            'unhandledrejection',
            {
                label: message,
                error: ev?.reason?.name,
                message,
            },
            { serverTimeOrConfigFactory: injector.get('ConfigFactory') },
        );
    });
    loggingUnhandledRejections = true;
}
