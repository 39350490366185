import angularModule from 'FrontRoyalUiBootstrap/angularModule/scripts/front_royal_ui_boostrap_module';
// Loosely based on http://jsfiddle.net/raving/2thfaxeu/
/*
	This code makes it so that you can hover over an element to
	show a popover, and the popover will not disappear if the user
	moves her mouse away from the hovered element and onto the popover.

	Needed if you have something inside the popover you want
	the user to click on.
*/

import 'FrontRoyalUiBootstrap/popover';

angularModule.directive('popoverInteractive', [
    '$injector',
    function factory($injector) {
        const $timeout = $injector.get('$timeout');
        const safeDigest = $injector.get('safeDigest');
        const $window = $injector.get('$window');
        const guid = $injector.get('guid');

        return {
            restrict: 'A',
            priority: 1, // before the various popover directives
            compile(elem, attrs) {
                if ($window.Modernizr.touchevents) {
                    attrs.popoverTrigger = "'outsideClick'"; // See https://github.com/angular-ui/bootstrap/issues/6212
                } else {
                    attrs.popoverTrigger = attrs.popoverTrigger || 'none';
                }

                const interactivePopoverId = guid.generate();

                attrs.popoverIsOpen = `interactivePopoverIsOpen("${interactivePopoverId}")`;

                if (attrs.popoverAppendToBody) {
                    throw new Error('popover-interactive does not work with popover-append-to-body');
                }

                return (scope, elem) => {
                    // since sibling interactivePopovers might share scope,
                    // we need to track the states of multiple things here
                    scope.openInteractivePopovers = scope.openInteractivePopovers || {};
                    scope.openInteractivePopovers[interactivePopoverId] = false;

                    scope.interactivePopoverIsOpen = interactivePopoverId =>
                        scope.openInteractivePopovers[interactivePopoverId];

                    function setPopoverIsOpenAndDigest(val) {
                        scope.openInteractivePopovers[interactivePopoverId] = val;
                        safeDigest(scope);
                    }

                    if (attrs.popoverTrigger !== "'outsideClick'") {
                        elem.on('mouseenter.popoverInteractive', () => {
                            setPopoverIsOpenAndDigest(true);
                        });

                        const popovers = [];

                        elem.on('mouseleave.popoverInteractive', evt => {
                            const timeout = $timeout(() => {
                                setPopoverIsOpenAndDigest(false);
                            }, 200);

                            const popover = $(evt.currentTarget).siblings('.popover');
                            popovers.push(popover);
                            popover.one('mouseenter', () => {
                                // We entered the actual popover – call off the dogs
                                $timeout.cancel(timeout);
                                // Let's monitor popover content instead
                                popover.one('mouseleave.popoverInteractive click.popoverInteractive', () => {
                                    setPopoverIsOpenAndDigest(false);
                                });
                            });
                        });

                        scope.$on('$destroy', () => {
                            popovers.forEach(popover => {
                                popover.off('.popoverInteractive');
                            });

                            elem.off('.popoverInteractive');
                        });
                    }
                };
            },
        };
    },
]);
