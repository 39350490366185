import '@sentry/tracing'; // see https://github.com/getsentry/sentry-javascript/issues/4731#issuecomment-1126157027
import { type AnyObject } from '@Types';
import { type EventLogger } from 'EventLogger/EventLogger.types';
import { EventEmitter } from 'events';
import { startSentryTransaction } from 'ErrorLogging';
import Timer from './Timer';
import { type StartTimerOpts } from './FrontRoyalTimer.types';

/*
    This class can be used when you want to measure the time between two different events, and then want
    to log the result using EventLogger.

    It is implemented as a Singleton because it needs to keep track of the active timers at a global level. When a timer is started,
    it is saved in the Singleton so it can be accessed and updated by code elsewhere in the app.

    Usage 1: Recording how long something takes

        // code_that_runs_when_the_user_clicks_a_button.js

        myTimerSingleton.startTimer('clickedToNavigateToSomewhere');

        // code_that_runs_once_we_get_somewhere.js

        myTimerSingleton.finishTimer('clickedToNavigateToSomewhere', 'navigated_to_somewhere', {some_more_information: 'here});

    Usage 2: Recording how long each step of a multistep process takes:


        // code_that_runs_when_the_user_clicks_a_button.js

        myTimerSingleton.startTimer('clickedToNavigateToSomewhere');

        // code_that_runs_once_we_have_completed_something_that_happens_before_we_get_there.js

        myTimerSingleton.startTimer('recordStep', 'finished_loading_some_data', {some_more_information: 'here});

        // code_that_runs_once_we_get_somewhere.js

        myTimerSingleton.finishTimer('clickedToNavigateToSomewhere', 'navigated_to_somewhere', {some_more_information: 'here});

    Options:

        * timeToKeep:
            In order to preserve memory, timers will be cleared after 1 minute by default. If you want to time something
            that takes longer than that, set timeToKeep
        * debug:
            If debug is set to true, then the times will be logged out to the console once finishTimer is called
        * sentryTransactionName:
            If this is set to a string, then a sentry transaction will be created with that name. The transacion will
            finish when the timer finishes. To add spans within the transaction, do

                const span = timer.transaction?.startChild({
                    description: 'spanName',
                });
                ...
                span.finish()

            Make sure to use the elvis operator, as `transaction` will not be defined when sentry is disabled for
            local development.

            Consider updating tracesSampler to limit the number of traces sent to sentry for your new transaction name.

*/

const ONE_MINUTE = 60 * 1000;

export default class TimerSingleton extends EventEmitter {
    #timers: AnyObject<Timer> = {};
    #EventLogger: EventLogger;
    injector: angular.auto.IInjectorService;

    constructor(injector: angular.auto.IInjectorService) {
        super();
        this.injector = injector;
        this.#EventLogger = injector.get('EventLogger');
    }

    startTimer(timerKey: string, opts: StartTimerOpts = {}) {
        const { timeToKeep, debug, sentryTransactionName, sentryTags } = {
            timeToKeep: ONE_MINUTE,
            debug: false,
            sentryTransactionName: null,
            sentryTags: {},
            ...opts,
        };

        // It's a little awkward to set up the sentry transaction here rather than inside
        // of the Timer class, but I wanted to avoid bucket-brigading 3 extra arguments here
        const sentryTransaction = sentryTransactionName
            ? startSentryTransaction(this.injector, sentryTransactionName, sentryTags)
            : null;
        const timer = new Timer({ debug, sentryTransaction });
        this.#timers[timerKey] = timer;

        setTimeout(() => {
            const currentTimeForThisKey = this.#timers[timerKey];
            if (currentTimeForThisKey === timer) {
                delete this.#timers[timerKey];
            }
        }, timeToKeep);

        return timer;
    }

    recordStep(timerKey: string, eventType: string, logInfo: AnyObject) {
        const [timer, duration] = this.#addStep(timerKey, eventType);
        if (timer === null) {
            return false;
        }
        this.#log(eventType, duration!, logInfo);
        return true;
    }

    finishTimer(timerKey: string, finalEventType: string, logInfo: AnyObject = {}) {
        const [timer] = this.#addStep(timerKey, 'finish');
        if (!timer) {
            return false;
        }
        const totalDuration = Date.now() - timer.startTime;

        this.#log(finalEventType, totalDuration, logInfo);
        timer.afterFinish(finalEventType, totalDuration);
        return true;
    }

    // This function emits an event with the logInfo. Anyone else who is listening
    // to this event can add information to the logInfo (See PerformanceTest)
    #emitLogInfo(eventType: string, totalDuration: number, logInfo: AnyObject) {
        const modifiedLogInfo = {
            ...logInfo,
        };
        this.emit('timerStep', eventType, totalDuration, modifiedLogInfo);
        return modifiedLogInfo;
    }

    #addStep(timerKey: string, eventType: string) {
        const timer = this.#timers[timerKey];
        if (!timer) {
            return [null, null];
        }
        const duration = Date.now() - timer.lastStepTime;
        timer.lastStepTime = Date.now();
        timer.addStep({ eventType, duration });
        return [timer, duration] as const;
    }

    #log(eventType: string, duration: number, logInfo: AnyObject) {
        const modifiedLogInfo = this.#emitLogInfo(eventType, duration, logInfo);
        this.#EventLogger.log(eventType, {
            value: duration,
            ...modifiedLogInfo,
        });
    }
}

export { TimerSingleton };
