import { type FC } from 'react';
import { createRoot } from 'react-dom/client';
import { type auto, type IAugmentedJQuery, type IChangesObject, type IComponentOptions } from 'angular';
import { type AnyObject } from '@Types';
import { storeProvider as _storeProvider, storeProvider } from 'ReduxHelpers';
import { ReactWrapper } from 'FrontRoyalReact/ReactWrapper';

type OnChanges<T> = {
    [K in keyof T]: IChangesObject<T[K]>;
};
type EmptyObject = AnyObject<never>;
type Bindings<T> = {
    [key in keyof T]: '<';
};

const didPropsChange = <T extends AnyObject>(newProps: Partial<T>, oldProps: Partial<T>) =>
    Object.entries(newProps).some(([key, value]) => oldProps[key] !== value);

export const react2Angular = <Props extends AnyObject = EmptyObject>(
    Component: FC<Props>,
    bindingNames: (keyof Props)[] = [],
    classNames = '',
    targetTailwindBaseStyles = true,
): IComponentOptions => {
    const bindings = bindingNames.reduce((names, next) => ({ ...names, ...{ [next]: '<' } }), {} as Bindings<Props>);

    const controller = [
        '$element',
        '$injector',
        class {
            isInitialRender = true;
            props = {} as Props;
            isDestroyed = false;
            root: ReturnType<typeof createRoot>;
            injector: auto.IInjectorService;

            constructor(private $element: IAugmentedJQuery, $injector: auto.IInjectorService) {
                this.root = createRoot(this.$element[0]);
                this.injector = $injector;
            }

            static get $$ngIsClass() {
                return true;
            }

            $onChanges(changes: OnChanges<Partial<Props>>) {
                const prevProps = this.props;

                const updatedProps = Object.entries(changes).reduce<Partial<Props>>(
                    (props, [key, { currentValue }]) => {
                        props[key as keyof Partial<Props>] = currentValue;
                        return props;
                    },
                    {},
                );

                const nextProps = { ...this.props, ...updatedProps };

                if (this.isInitialRender) {
                    this.props = nextProps;
                    this.renderReactComponent();
                    this.isInitialRender = false;
                } else if (didPropsChange(updatedProps, prevProps)) {
                    this.props = nextProps;
                    this.renderReactComponent();
                }
            }

            $onDestroy() {
                this.unmountReactComponent();
            }

            unmountReactComponent() {
                this.isDestroyed = true;
                this.root.unmount();
            }

            renderReactComponent() {
                const $window = this.injector.get<Window>('$window');

                // In lesson preview mode, the storeProvider is attached to the iframe.contentWindow
                // See edit_lesson_preview_mode_dir.js
                const currentStoreProvider = ($window.storeProvider || storeProvider) as typeof storeProvider;

                if (!this.isDestroyed) {
                    // The .tailwind-base-container class is used as a target for the custom Tailwind CSS preflight styles.
                    this.root.render(
                        <ReactWrapper
                            angularInjector={this.injector}
                            classNames={classNames}
                            targetTailwindBaseStyles={targetTailwindBaseStyles}
                            store={currentStoreProvider.store!}
                        >
                            <Component {...this.props} />
                        </ReactWrapper>,
                    );
                }
            }
        },
    ] as IComponentOptions['controller'];

    return { bindings, controller };
};

export default react2Angular;
