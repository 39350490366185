import { type MaybeError } from '@Types';
import FrontRoyalStoreDB from './FrontRoyalStoreDB';
import supportedDbTechnology, { FAKE_INDEXED_DB } from './supportedDbTechnology';
import recoverFromVersionError from './recoverFromVersionError';
// For debugging dexie issues
// Dexie.debug = true;

export default async function openDb(dbTechnology?: string | null) {
    dbTechnology = dbTechnology || supportedDbTechnology();
    let db;
    try {
        db = new FrontRoyalStoreDB(dbTechnology);
    } catch (obj) {
        const err = obj as MaybeError;

        // This happens, for example, when in FF private browsing mode
        if (err?.name === 'OpenFailedError' && err.message?.match('InvalidStateError')) {
            db = new FrontRoyalStoreDB(FAKE_INDEXED_DB);
        }

        const recoverPromise = recoverFromVersionError(err, dbTechnology);
        if (recoverPromise) {
            db = await recoverPromise;
        }

        if (!db) throw err;
    }
    return db;
}
