import { camelCaseStream, type Stream } from 'Lessons';
import type FrontRoyalStoreDB from './FrontRoyalStoreDB';
import { camelizePublishedLessonContent } from './publishedLessonContent';

export function getPublishedStream({
    id,
    loadFullContentForLessonId,
    loadFullContentForAllLessons,
}: {
    id: string;

    // "Full content" refers to the full details of frames content
    // in lessons.frames. This stuff is stored in publishedLessonContent. This
    // function copies the data from publishedLessonContent into the returned stream.
    loadFullContentForLessonId?: string | null;
    loadFullContentForAllLessons?: boolean;
}) {
    loadFullContentForLessonId ||= null;
    loadFullContentForAllLessons ||= false;

    return async (db: FrontRoyalStoreDB) => {
        // grab the stream from the store and convert it to camelCase
        const snakeCasedStream = await db.publishedStreams.get(id);
        if (!snakeCasedStream) return null;
        const stream = camelCaseStream(snakeCasedStream);

        // If there are lessons that need frame content, copy that
        // information out of publishedLessonContent and into the stream record
        const { fullContentUnavailable } = await addFullLessonContent({
            db,
            stream,
            loadFullContentForLessonId,
            loadFullContentForAllLessons,
        });

        // If we failed to find all the frame content we needed in the store,
        // return null
        if (fullContentUnavailable) return null;

        return stream;
    };
}

async function addFullLessonContent({
    loadFullContentForLessonId,
    loadFullContentForAllLessons,
    db,
    stream,
}: {
    loadFullContentForLessonId: string | null;
    loadFullContentForAllLessons: boolean;
    db: FrontRoyalStoreDB;
    stream: Stream;
}): Promise<{ fullContentUnavailable: boolean }> {
    let lessonIds: string[] = [];
    if (loadFullContentForLessonId) {
        lessonIds = [loadFullContentForLessonId];
    }
    if (loadFullContentForAllLessons) {
        lessonIds = stream.lessons.map(lesson => lesson.id);
    }
    if (!lessonIds.length) return { fullContentUnavailable: false };

    const publishedLessonContentRecords = (
        await db.publishedLessonContent
            .where('[stream_id+id]')
            .anyOf(lessonIds.map(lid => [stream.id, lid]))
            .toArray()
    ).map(r => camelizePublishedLessonContent(r));

    const lessonsThatNeedFullContent = stream.lessons.filter(lesson => lessonIds.includes(lesson.id));
    const lessonMissingFullContent = lessonsThatNeedFullContent.find(lesson => {
        const publishedLessonContentRecord = publishedLessonContentRecords.find(r => r.id === lesson.id);
        if (!publishedLessonContentRecord) return true;
        lesson.frames = publishedLessonContentRecord.frames;
        lesson.devicePixelRatio = publishedLessonContentRecord.devicePixelRatio;
        lesson.isMobile = publishedLessonContentRecord.isMobile;
        return false;
    });

    return { fullContentUnavailable: !!lessonMissingFullContent };
}
