import { type IconProps } from './Icon.types';
import { GlobalIconComponents } from './IconData';

export function Icon({ iconName, ...rest }: IconProps) {
    if (!(iconName in GlobalIconComponents) && process.env.NODE_ENV === 'development') {
        throw new Error(`Icon "${iconName}" does not exist.`);
    }

    const IconComponent = GlobalIconComponents[iconName];

    return <IconComponent {...rest} data-testid="svg-icon" />;
}
