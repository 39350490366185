/*
    This file contains the request and response error interceptors for the front royal store.
    These interceptors are responsible for intercepting API requests intended for specific
    endpoints and ensuring that the requested changes are persisted to the store prior to
    the API request getting triggered. Note that while the user is online, there are changes
    that can be sent down from the server that are then persisted to the store, but since
    this can't happen in offline mode, the logic for those interceptors is kept outside
    of the FrontRoyalStore module (see UserProgressLoaderInterceptor for an example).
    In other words, the front royal store interceptors in this file are primarily about
    handling save calls.
*/

import { currentUserRequestInterceptor } from 'StoredCurrentUser';
import { lessonProgressRequestInterceptor } from 'StoredProgress';
import { eventRequestInterceptor } from 'StoredEvent';
import BlockedByFrontRoyalStoreError from './BlockedByFrontRoyalStoreError';

// This should be LOWER than the HttpQueueAndRetry interceptor so that it's given
// a HIGHER priority, meaning that it runs BEFORE the HttpQueueAndRetry interceptor.
// We do this so that if an event bundles or lesson progress save request triggers
// a QuotaExceededError, we handle it before the request is added to the queue.
// That way we don't block up the queue.
export const INTERCEPTOR_PRIORITY = -500;

async function runRequestInterceptor(interceptor, config, $injector) {
    const handler = await interceptor(config, $injector);

    if (handler) {
        throw new BlockedByFrontRoyalStoreError(handler, config);
    }
}

async function runRequestInterceptors(config, $injector) {
    // Since events can be bundled into other calls, and the eventInterceptor
    // will remove them from config, we let the eventInterceptor run and finish
    // first before running any other interceptors
    await runRequestInterceptor(eventRequestInterceptor, config, $injector);

    const promises = [currentUserRequestInterceptor, lessonProgressRequestInterceptor].map(interceptor =>
        runRequestInterceptor(interceptor, config, $injector),
    );
    await Promise.all(promises);
    return config;
}

// NOTE: This used to be declared as an `async` function even though it
// didn't use `await` in its logic. This caused a series of bugs to appear
// in the app in seemingly unrelated places (see: https://trello.com/c/OeGht4hG,
// https://trello.com/c/Ln3KWfnQ, and https://trello.com/c/bierK144).
// It seems like making it `async` shouldn't make a difference, but it did
// and at this point we're still not sure why. So, proceed with caution if
// you're chancing this function to be `async`.
export function requestInterceptor(config, $injector) {
    const $window = $injector.get('$window');

    // Some requests get messed up if we return a promise
    // from this interceptor (like templates, maybe?).  So,
    // we short-circuit early unless this is a request to our api.
    if (!config.url.match(`${$window.ENDPOINT_ROOT}/api`)) {
        return config;
    }

    // If this is a call to our API and ghostMode is set to true then let our server know
    //
    // NOTE: We need to check ClientStorage here to accommodate for any requests made
    // in between performing the log-in-as and setting $rootScope.currentUser.ghostMode,
    // which only happens after a successful request to the log-in-as endpoint. While
    // we could completely rely on this ClientStorage check, we can avoid the storage
    // lookup once `ghostMode` is set on the user.
    const $rootScope = $injector.get('$rootScope');
    const ClientStorage = $injector.get('ClientStorage');
    if (!!$rootScope.currentUser?.ghostMode || !!ClientStorage.getItem('logged_in_as')) {
        config.params = config.params || {};
        config.params.ghost_mode = true;
    }

    return runRequestInterceptors(config, $injector);
}

export function responseErrorInterceptor(response) {
    if (response && response.constructor === BlockedByFrontRoyalStoreError) {
        return response.handleRequest();
    }
    return Promise.reject(response);
}
