import angularModule from 'FrontRoyalTimer/angularModule/scripts/front_royal_timer_module';

/*
    This is an attribute directive. You can add it to any element, and then when the element is clicked, it
    Timer.startTimer will be triggered.

    Example:

        // my_template.html
        <a
            class="my-button"
            ng-click="navigateSomewhere()"
            start-timer-on-click="'clickedToNavigateToSomewhere'"
        ></a>

        // some_code_that_runs_once_we_get_somewhere.js

        Timer.finishTimer('clickedToNavigateToSomewhere', 'navigated_to_somewhere', {some_more_infomation: 'here});
*/
angularModule.directive('startTimerOnClick', [
    '$injector',
    function factory($injector) {
        const timerSingleton = $injector.get('timerSingleton');

        return {
            restrict: 'A',
            link(scope, element, attrs) {
                $(element).on('click', () => {
                    const timerKey = scope.$eval(attrs.startTimerOnClick);
                    timerSingleton.startTimer(timerKey);
                });
            },
        };
    },
]);
