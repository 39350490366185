import { type MaybeError } from '@Types';
import Dexie from 'dexie';
import FrontRoyalStoreDB from './FrontRoyalStoreDB';

// In dev mode, you might switch to a branch that has an earlier version
// of the database.  In that case, we catch the VersionError, delete the
// existing database, and re-initialize using the code that you are
// currently using. (We cannot use our normal strategy of checking for dev
// mode here, because we might not have config yet). FIXME: do we need
// to add UpgradeError here?
export default function recoverFromVersionError(err: MaybeError, dbTechnology: string) {
    const onDev = window.location.hostname.match(/localhost/) || window.location.hostname.match(/ngrok/);
    if (!onDev) {
        return null;
    }
    const shouldTryToRecover = err?.message?.match('VersionError');

    if (!shouldTryToRecover) {
        return null;
    }

    return reinitializeDb(dbTechnology);
}

async function reinitializeDb(dbTechnology: string) {
    await Dexie.delete('FrontRoyal');
    // eslint-disable-next-line no-console
    console.log(' ***** Recover from VersionError ');
    return new FrontRoyalStoreDB(dbTechnology);
}
