import { memo } from 'react';

function ModalBackdropComponent({ children }: { children: React.ReactNode }) {
    return (
        <div className="tw-fixed tw-inset-0 tw-z-50 tw-bg-black tw-bg-opacity-50 tw-px-2 tw-pt-[55px] tw-backdrop-blur-sm sm:tw-pt-[75px]">
            {children}
        </div>
    );
}

export const ModalBackdrop = memo(ModalBackdropComponent) as typeof ModalBackdropComponent;
